import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { errorHints } from '../../consts/validation';
import { requiredFormLang } from '../../consts/app';
import { formatPhoneInternational } from './phone';
import { formatNotNumberToNumber } from './numbers';

export const isEmpty = (value?: any): boolean => {
  if (Array.isArray(value)) {
    return value.length === 0;
  }
  if (typeof value === 'number') {
    return Number.isNaN(value);
  }
  return !value;
};

export const isRusLanguage = (value: string | undefined) => {
  return value?.match(/[A-Za-z]/)?.length;
};

export const isNumbersCount = (value: string, count: number): boolean => {
  return value.replace(/\D/g, '').length === count;
};

interface Violation {
  message: string;
  propertyPath: string;
}

const setObjectFromViolation = (obj: any = {}, violation: Violation) => {
  const { message, propertyPath } = violation;
  const path = propertyPath.split('.');
  let currentObject = obj;
  path.forEach((key, index) => {
    if (index === path.length - 1) {
      currentObject[key] = message;
      return;
    }
    const isNextNumber = !Number.isNaN(Number(path[index + 1]));
    if (!currentObject.hasOwnProperty(key)) {
      if (isNextNumber) {
        currentObject[key] = [];
      } else {
        currentObject[key] = {};
      }
    }
    if (typeof currentObject[key] === 'object') {
      currentObject = currentObject[key];
    }
  });
};

export const convertViolationsToObject = (violations?: Record<number, Violation>) => {
  if (!violations) {
    return {};
  }
  const obj = {};
  Object.values(violations).forEach((violation) => {
    setObjectFromViolation(obj, violation);
  });
  return obj;
};

export const convertObjectToViolations = (errors?: any, res: any = {}, path = '') => {
  if (typeof errors !== 'object' && !isEmpty(errors)) {
    res[path] = errors;
  } else if (Array.isArray(errors)) {
    errors.forEach((item, index) => {
      const newPath = `${path}[${index}]`;
      convertObjectToViolations(item, res, newPath);
    });
  } else if (errors !== null && typeof errors === 'object') {
    Object.keys(errors).forEach((key) => {
      const newPath = path ? `${path}.${key}` : key;
      convertObjectToViolations(errors[key], res, newPath);
    });
  }
  return res;
};

export const isEmailValid = (email: string) => {
  return /^([\w-\.]+@([\w-]+\.)+[\w-]{2,})?$/.test(email || '');
};

export const isHasWhiteSpace = (email: string) => {
  return /\s/.test(email || '');
};

export const createErrorsForRequiredMultiLangFields = <MultiLangFields>(
  values: any,
  fields: MultiLangFields[],
) => {
  const errors: any = {};
  fields.forEach((field) => {
    if (!values[field][requiredFormLang]) {
      errors[field] = errorHints.required;
    }
  });
  return errors;
};

export const isPhoneInternationalValid = (phone: string) => {
  const phoneFormatted = formatPhoneInternational(phone);
  return isPossiblePhoneNumber(phoneFormatted);
};

export const isCardExpirationDateValid = (expireDate: string): boolean => {
  if (!expireDate) {
    return false;
  }
  const [month, year] = expireDate.split('/');
  const monthNumber = formatNotNumberToNumber(month);
  const yearNumber = formatNotNumberToNumber(year);
  if (monthNumber === null || yearNumber === null) {
    return false;
  }
  return month.length === 2 && year.length === 2 && monthNumber > 0 && monthNumber <= 12;
};
