export type ValueNumber = number | string | null | undefined;

export interface Option {
  value: any;
  label: string;
}

export interface Credentials {
  // eslint-disable-next-line camelcase
  refresh_token: string;
  token: string;
}

export type OptionExtended<Extend = any> = Option & Extend;

export interface LayoutCommonProps {
  addPadding?: boolean;
  addPaddingExtensive?: boolean;
  widthMaxWidth?: boolean;
}

export interface DocumentCommon {
  id: number;
  filename: string;
  contentUrl: string;
  type?: string;
  fileSize?: string;
  createdAt?: string;
}

export type Lang = 'en' | 'ru' | 'zh' | 'ar';

export const vatExcludeSet = new Set([3, 4, 6]);
