/* eslint-disable camelcase */
import { AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import { VoucherInterface } from '@store/entities/voucher';
import { QUERY_PARAMS } from '@store/entities/table';
import axios from '../axios/axios';
import { LoggingPayload, LogFunctionData } from './types/logging';

const normalOperationCodes = new Set([400, 401, 403, 404, 409, 422]);

interface Itotal<Data = any> {
  data: Data;
  total: any;
}

export enum GENERIC {
  countries = '/countries',
  companies = '/companies',
  company_statuses = '/Companies/statuses',
  company_group_statuses = '/CompanyGroups/statuses',
  company_group_types = '/CompanyGroups/types',
  company_groups = '/company_groups',
  fiscal_accumulator_statuses = 'fiscal_accumulator/statuses',
  users = 'users',
  user_infos = 'user_infos/statuses',
  roles = 'groups',
  issuers_statuses = 'issuers/statuses',
  refund_statuses = 'refund_offices/statuses',
  units = '/units',
  brands_statuses = 'brands/statuses',
  shops_statuses = 'shops/statuses',
  brands_categories = 'brand_categories',
  refund_office_statuses = 'refund_offices/statuses',
  customs_offices = 'checkpoints',
  customs_office_statuses = 'checkpoint/statuses',
  mailbox_statuses = 'mail_boxes/statuses',
  issuers = '/issuers',
  fiscalAccumulators = 'fiscal_accumulators',
}

export type unitGenericPath =
  | GENERIC.countries
  | GENERIC.companies
  | GENERIC.company_statuses
  | GENERIC.company_group_statuses
  | GENERIC.company_group_types
  | GENERIC.company_groups
  | GENERIC.fiscal_accumulator_statuses
  | GENERIC.users
  | GENERIC.user_infos
  | GENERIC.roles
  | GENERIC.refund_statuses
  | GENERIC.issuers_statuses
  | GENERIC.units
  | GENERIC.brands_statuses;

interface ICrudHttp {
  login: <P>(login: string, password: string) => Promise<P>;
  generic: <P>(path: unitGenericPath, config?: AxiosRequestConfig) => Promise<P>;
  create: <T extends object>(payload: T, path: string) => Promise<string>;
  delete: <P>(path: string, id: string) => Promise<P>;
  add: <P>(path: string, data: object) => Promise<P | boolean>;
  get: <P>(path: string) => Promise<P>;
  getList: <Data = any>(path: string, config?: AxiosRequestConfig) => Promise<Itotal<Data>>;
  getByID: <P>(path: string, id: string, params?: any) => Promise<P>;
  editByID: (path: string, method: Method, data: object) => Promise<boolean>;
  postAxios: typeof axios.post;
  getAxios: typeof axios.get;
  patchAxios: typeof axios.patch;
  deleteAxios: typeof axios.delete;
  putAxios: typeof axios.put;
  getVoucherByHrIdentifier: (hrIdentifier: string) => Promise<VoucherInterface | null>;
  log: (e: unknown, context: LogFunctionData) => void;
}

export const HttpClient: ICrudHttp = {
  postAxios: axios.post,
  getAxios: axios.get,
  patchAxios: axios.patch,
  deleteAxios: axios.delete,
  putAxios: axios.put,

  login: async (login: string, password: string) => {
    const { data } = await axios.request({
      url: 'authentication_token',
      method: 'POST',
      data: {
        login,
        password,
      },
    });
    return data;
  },

  getVoucherByHrIdentifier: async (hrIdentifier) => {
    try {
      const { data } = await axios.get<VoucherInterface | null>('/vouchers/hr_identifier', {
        params: {
          hrIdentifier,
        },
      });
      return data || null;
    } catch (e) {
      HttpClient.log(e, {
        component: 'HttpClient',
        functionName: 'getVoucherByHrIdentifier',
        codeLine: 100,
        requestUrl: '/vouchers/hr_identifier',
        method: 'GET',
        request: {
          params: {
            hrIdentifier,
          },
        },
      });

      return Promise.reject(e);
    }
  },

  delete: async (path: string, id: string) => {
    const { data } = await axios.request({
      url: `/${path}/${id}`,
      method: 'DELETE',
    });
    return data;
  },

  create: async (payload, path: string) => {
    const { data }: { data: { identifier: string } } = await axios.post(path, payload);
    return data.identifier;
  },

  generic: async (path, config = {}) => {
    const { data } = await axios.get(`${path}`, {
      ...config,
      params: {
        [QUERY_PARAMS.limit]: 100,
        ...(config.params || {}),
      },
    });
    return data;
  },

  add: async (path: string, data: object) => {
    return axios.post(path, data).then((res: AxiosResponse) => {
      return res.status == 201;
    });
  },

  get: async (path: string) => {
    const { data } = await axios.get(path);
    return data;
  },

  getList: async (path: string, config: AxiosRequestConfig = {}) => {
    const { data, headers } = await axios.request({
      ...config,
      url: path,
      method: 'GET',
    });
    return { data, total: Number(headers['x-total-count']) }; // TODO i don't think it is a good idea use a custom header
  },

  getByID: async (path: string, id: string, params?: any) => {
    const { data } = await axios.request({
      url: `/${path}/${id}`,
      method: 'GET',
      params,
    });
    return data;
  },

  editByID: async (path: string, method: Method, data: object) => {
    return axios
      .request({
        url: path,
        method,
        data,
      })
      .then((res: AxiosResponse) => {
        return res.status === 200;
      });
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  log: async (err: any, context: LogFunctionData) => {
    if (normalOperationCodes.has(err?.response?.status)) return;
    try {
      const payload: LoggingPayload = {
        message: 'no error message',
        source: 'app',
        context: {
          ...context,
          error: JSON.stringify(err),
          request: JSON.stringify(context.request),
        },
      };

      if (err instanceof Error) {
        payload.message = err.message;
      }

      HttpClient.postAxios('/log', payload);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  },
};
