import { CSSProperties, FC, useContext } from 'react';
import { Col, ColProps } from 'react-grid-system';
import { GridContext } from '@common/GridContainer';

interface Props extends ColProps {
  alignSelf?: CSSProperties['alignSelf'];
}

const GridItem: FC<Props> = (props) => {
  const { alignSelf, ...rest } = props;
  const { gutterWidth } = useContext(GridContext);
  return <Col {...(rest as any)} style={{ padding: gutterWidth / 2, alignSelf }} />;
};

export default GridItem;
