import { FC, useCallback, ChangeEvent, FocusEvent, MutableRefObject, memo } from 'react';
import {
  Input as SbBolInput,
  IInputProps,
} from '@sbbol/web-library/desktop/components/Input/Input';
import classNames from 'classnames';
import SpinnerIcon from '@common/SpinnerIcon';
import { isOnlyLatinRegExp, phoneNumberRegExp, passportNumberRegExp } from '@consts/validation';
import styles from './styles.module.scss';

interface Params {
  name?: string;
}

export interface Props extends Omit<IInputProps, 'onChange' | 'className' | 'onBlur'> {
  onChange: (value: string, params: Params) => void;
  onBlur?: (value: string, params: Params) => void;
  className?: string;
  loading?: boolean;
  isOnlyLatin?: boolean;
  phoneNumber?: boolean;
  passportNumber?: boolean;
  isUpperCase?: boolean;
  outerInputRef?: MutableRefObject<HTMLInputElement | null>;
  disabled?: boolean;
}

const Input: FC<Props> = (props) => {
  const {
    onChange,
    name,
    onBlur,
    className,
    loading,
    isOnlyLatin,
    isUpperCase,
    passportNumber,
    outerInputRef,
    phoneNumber,
    disabled,
    ...rest
  } = props;

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;

      if (isOnlyLatin && !isOnlyLatinRegExp.test(inputValue)) {
        return;
      }
      if (phoneNumber && !phoneNumberRegExp.test(inputValue)) {
        return;
      }
      if (passportNumber && !passportNumberRegExp.test(inputValue)) {
        return;
      }
      onChange(isUpperCase ? inputValue.toUpperCase() : inputValue, { name });
    },

    [isOnlyLatin, phoneNumber, passportNumber, onChange, isUpperCase, name],
  );

  const handleBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      onBlur?.(e.target.value, { name });
    },
    [onBlur, name],
  );

  const setInputRef = useCallback(
    (ref: HTMLInputElement | null) => {
      if (ref) {
        if (outerInputRef) {
          outerInputRef.current = ref;
        }
      }
    },
    [outerInputRef],
  );

  return (
    <div className={classNames(styles.container, className)}>
      {loading && <SpinnerIcon className={styles.spinnerWrapper} />}
      <SbBolInput
        {...rest}
        disabled={disabled}
        setInputRef={setInputRef}
        onChange={handleChange}
        name={name}
        onBlur={handleBlur}
      />
    </div>
  );
};

export default memo(Input);
