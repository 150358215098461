/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
import { DateRangePickerValue } from '@common/DateRangePicker';
import { IVoucherAPIConsumer, VoucherFiscalReceipt } from '@services/api/types/vouchers';
import { ICompany, ICompanyInfo, IGenericVat } from './generic';
import type { Registry } from './registry';
import { IRefundPointAPI } from './refund';
import { IIssuer } from '../../components/issuers/types';
import { IssuerPointAPI } from './issuers';

export enum VoucherReceiptType {
  INCOME = 'income',
}
export interface IVoucherReceiptItemData {
  id?: string;
  article: string;
  label: string;
  pricePerItem: string;
  quantity: string;
  quantityMax: string;
  totalPrice: string;
  measure: number;
  vat: string;
  isRefundable: boolean;
}

export interface IVoucherReceiptData {
  id?: string;
  number: string;
  fiscalAccumulator: string;
  type: VoucherReceiptType;
  items: {
    [id: string]: IVoucherReceiptItemData;
  };
  totalAmount: string;
  taxationAmount: string;
  refundableAmount: string;
  issuedAt?: string;
  vatRaits?: IGenericVat[];
  company?: string;
}

export interface ISerializableVoucherReceiptData {
  identifier?: string;
  number: string;
  fiscalAccumulator: string;
  type: VoucherReceiptType;
  company?: string;
  issuedAt?: string;
  items: Array<IVoucherReceiptItemData & { position: number }>;
  totalAmount: string;
  taxationAmount: string;
  refundableAmount: string;
  vatRaits?: IGenericVat[];
}

export interface IVoucherData {
  identifier?: string;
  hrIdentifier?: string;
  company: string;
  issuedAt: string;
  fiscalReceipts: Record<string, IVoucherReceiptData>;
  totalAmount: string;
  taxationAmount: string;
  refundableAmount: string;
  status: string;
}

export interface ISerializableVoucherData {
  identifier?: string;
  hrIdentifier?: string;
  company: string;
  issuedAt: string;
  fiscalReceipts: ISerializableVoucherReceiptData[];
  totalAmount: string;
  taxationAmount: string;
  refundableAmount: string;
  status?: string;
}

export interface ICompanyActiveVoucher {
  [key: string]: string | ICompanyInfo;
  id: string;
  name: string;
  status: string;
  companyInfo: ICompanyInfo;
  createdAt: string;
  updatedAt: string;
}

export interface IActiveVoucherAPIData {
  companyInfo: ICompanyActiveVoucher;
  issuedAt: string;
  identifier: string;
  totalAmount: string;
  taxationAmount: string;
  refundableAmount: string;
}

export enum VoucherStatus {
  CREATED = 'created',
  STAMPED = 'stamped',
  REFUNDED_TO_CARD = 'refunded_to_card',
  REFUNDED_BY_CASH = 'refunded_by_cash',
  IN_PROGRESS = 'in_progress',
  SENT_TO_BANK = 'sent_to_bank',
  FOR_BANK_PAY = 'for_bank_pay',
  REFUSAL = 'refusal',
  REFUSAL_BY_BANK_REJECTED = 'refusal_by_bank_rejected',
  REFUSAL_NO_CUSTOMS_MARK = 'refusal_no_customs_mark',
  REFUSAL_NO_PAYMENT_METHOD = 'refusal_no_payment_method',
  REFUSAL_BY_EXPORT_PERIOD = 'refusal_by_export_period',
  REFUSAL_BY_REFUND_PERIOD = 'refusal_by_refund_period',
  ERROR = 'error',
  ERROR_NO_CUSTOMS_MARK = 'error_no_customs_mark',
  ERROR_NO_PAYMENT_METHOD = 'error_no_payment_method',
  ERROR_BANK_REJECTED = 'error_bank_rejected',
  CANCELLED_BY_REFUND_OFFICE = 'cancelled_by_refund_office',
  CANCELLED_BY_OPERATOR = 'cancelled_by_operator',
  CANCELLED_BY_SHOP = 'cancelled_by_shop',
  REGISTERED = 'registered',
  REJECTED_BY_FCS = 'rejected_by_fcs',
}

export interface VoucherStatusHistory {
  createdAt: string;
  updatedAt: string;
  newStatus: VoucherStatus;
  oldStatus: VoucherStatus;
}

export interface VoucherCustomsMark {
  id?: number;
  customsCode: string;
  stampNumber: string;
  markedAt: string;
  personName: string;
  personSurname: string;
  personMiddleName: string;
  isMarkElectronic: boolean;
}

export interface VoucherInterface {
  id: string;
  identifier: string;
  hrIdentifier: string;
  createdAt: string;
  paidAt: string | null;
  updatedAt: string;
  status: VoucherStatus;
  statusBeforePaymentInfoChange?: VoucherStatus;
  refundableAmount: number | string;
  totalAmount: number | string;
  taxationAmount: number | string;
  company: ICompany;
  consumer: IVoucherAPIConsumer;
  customsMark: VoucherCustomsMark | null;
  fiscalReceipts: VoucherFiscalReceipt[];
  voucherRegistries?: Registry[];
  issuedAt: string;
  voucherStatusHistories: VoucherStatusHistory[];
  refundOffice?: IRefundPointAPI;
  paymentInfo?: VoucherPaymentInfo | null;
  voucherIssues: VoucherIssuesType;
  issuer: IIssuer;
  isReceivedByOperator?: boolean; // Получен при проверке реестра
  isVerifiedByOperator?: boolean; // Принят при проверке реестра
  refundPercentFor10Vat: string;
  refundPercentFor20Vat: string;
}

export interface VouchersFilter {
  hrIdentifier: string;
  createdAt: DateRangePickerValue | null;
  paidAt: DateRangePickerValue | null;
  company: ICompany | null;
  status: string | null;
  statusArray: string[];
  fiscalTotalAmount: string | null;
  fiscalAccumulatorNumber: string | null;
  fiscalIssuedAt: string | null;
  refundOffice: IRefundPointAPI | null;
  issuer: IssuerPointAPI | null;
}

export interface Totals {
  totalAmount: number;
  taxationAmount: number;
  refundableAmount: number;
}

export interface VoucherCardForm {
  cardNumber: string;
  cardExpirationDate: string;
  phoneNumber: string;
  email: string;
  issueComment: string;
  voucherIssues: string[];
  isStamped: boolean;
  customsCode: string;
  stampNumber: string;
  markedAt: string;
}

export interface VoucherPaymentInfo {
  id: string;
  cardNumber: string;
  cardExpirationDate: string;
  phoneNumber: string;
  email: string;
  issueComment: string;
}

export interface VoucherIssuesType {
  [key: string]: number | boolean | null;
}

export type VoucherIssueCategory =
  | 'credit_card_data'
  | 'customs_data'
  | 'expired_period'
  | 'not_valid_voucher_status'
  | 'voucher_filling_out';

export interface VoucherIssueManual {
  type: VoucherIssuesType;
  category: VoucherIssueCategory;
  disabled: boolean;
  status_before: VoucherStatus[];
  status_after: VoucherStatus | null;
  status_error: VoucherStatus | null;
  priority: number;
}

export interface FeeRates {
  [key: number]: number;
}

export interface FeeRateStings {
  refundPercentFor10Vat: string;
  refundPercentFor20Vat: string;
}
