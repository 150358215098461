import { makeAutoObservable, toJS } from 'mobx';
import {
  initialVouchersPaidFilter,
  initialRegistryFilter,
  initialFiscalAccumulators,
  initialUsersFilter,
  initialCompanyFilter,
  initialCompanyGroupsFilter,
  initialRefundsFilter,
  initialVouchersFilter,
  initialIssuersFilter,
  initialCashiersRefundFilter,
  initialCashiersIssuerFilter,
  initialPaymentHistoryFilter,
  initialBrandsFilter,
  initialShopsFilter,
  initialCustomsOfficesFilter,
  initialRefundOfficesFilter,
  initialMailboxesFilter,
  initialRegistrySendFilter,
  initialPaymentOrdersFilter,
  initialRegistryToPayFilter,
  initialCitiesFilter,
  initialLogisticRegistriesFilter,
  initialIntegrationsFilter,
} from '@consts/filters';
import type { RootStore } from './index';
import { IFilters } from './entities/table';

export default class FilterTableStore {
  filters: IFilters = {
    paymentOrders: { ...initialPaymentOrdersFilter },
    companies: { ...initialCompanyFilter },
    vouchersCashierPaid: { ...initialVouchersPaidFilter },
    paymentHistory: { ...initialPaymentHistoryFilter }, // бухгалтер оператора
    registryToPay: { ...initialRegistryToPayFilter }, // к оплате
    registry: { ...initialRegistryFilter }, // для оператора
    registryNotPaid: { ...initialRegistryFilter },
    registryCashPaid: { ...initialRegistryFilter },
    registrySendPaidByCash: { ...initialRegistrySendFilter }, // оплаченных для админки возрата
    registrySendNotPaid: { ...initialRegistrySendFilter }, // неполаченных для админки возрата
    registryProcessingPaidByCash: { ...initialRegistryFilter }, // оплаченных для процессинга
    registryProcessingNotPaid: { ...initialRegistryFilter }, // неполаченных для процессинга
    logisticRegistries: { ...initialLogisticRegistriesFilter }, // сбер логистика
    fiscalAccumulators: { ...initialFiscalAccumulators },
    users: { ...initialUsersFilter },
    companyGroups: { ...initialCompanyGroupsFilter },
    refunds: { ...initialRefundsFilter },
    vouchers: { ...initialVouchersFilter },
    issuers: { ...initialIssuersFilter },
    cashiersRefund: { ...initialCashiersRefundFilter },
    cashierIssuer: { ...initialCashiersIssuerFilter },
    brands: { ...initialBrandsFilter },
    shops: { ...initialShopsFilter },
    customsOffices: { ...initialCustomsOfficesFilter },
    refundOffices: { ...initialRefundOfficesFilter },
    mailboxes: { ...initialMailboxesFilter },
    cities: { ...initialCitiesFilter },
    integrations: { ...initialIntegrationsFilter },
  };

  constructor(private rootState: RootStore) {
    makeAutoObservable(this);
  }

  getFilterValue = (key: keyof IFilters) => {
    return this.filters[key];
  };

  setFilterValue = (key: keyof IFilters, value: any) => {
    this.filters[key] = toJS(value);
  };

  isFilterValueChanged = (key: keyof IFilters, initialValue: any): boolean => {
    // return !isEqual(this.filters[key], initialValue);
    return JSON.stringify(this.filters[key]) !== JSON.stringify(initialValue);
  };
}
