const STORAGE_KEYS = {
  sbbolReturnTo: 'sbbolReturnTo',
  CREDENTIALS: 'credentials',
  EXITPOINT: 'EXITPOINT',
  ENTRANCE: 'ENTRANCE',
  CHATINFO: 'CHATINFO',
  ISMENUOPEN: 'ISMENUOPEN',
};

enum ENTER_POINTS {
  TAXFREE = 'TAXFREE',
  LINK = 'LINK',
}

export const exitRouteMap: Record<string, string> = {
  [ENTER_POINTS.TAXFREE]: '/login',
  [ENTER_POINTS.LINK]: '/link/login',
};

const DISABLE = 'DISABLE';

const { CREDENTIALS, EXITPOINT, ISMENUOPEN } = STORAGE_KEYS;

export function getStorageValue<Value = any>(key: string): Value | null {
  try {
    const storageValue = localStorage.getItem(key);
    return storageValue ? JSON.parse(storageValue) : null;
  } catch (e) {
    console.error('Ошибка парсинга localStorage', e);
  }
  return null;
}

export function getSessionValue<Value = any>(key: string): Value {
  const storageValue = sessionStorage.getItem(key);
  return storageValue ? JSON.parse(storageValue) : null;
}

export const setStorageValue = (key: string, value: unknown): void =>
  localStorage.setItem(key, JSON.stringify(value));

export const setSessionValue = (key: string, value: unknown): void =>
  sessionStorage.setItem(key, JSON.stringify(value));

export const removeCredentials = (): void => localStorage.removeItem(CREDENTIALS);

export const setCredentials = (value: string): void => localStorage.setItem(CREDENTIALS, value);

export const getCredentials = (): string | null => localStorage.getItem(CREDENTIALS);

export const setExitPointTAXFREE = (): void =>
  localStorage.setItem(EXITPOINT, ENTER_POINTS.TAXFREE);

export const setExitPointLINK = (): void => localStorage.setItem(EXITPOINT, ENTER_POINTS.LINK);

export const getExitPoint = (): string => {
  const exitPointValue = localStorage.getItem(EXITPOINT);
  return exitPointValue ? exitRouteMap[exitPointValue] : '/login';
};

export const setEntrancePoint = (): void =>
  localStorage.setItem(STORAGE_KEYS.ENTRANCE, window.location.pathname);

export const getEntrancePoint = (): string | null => localStorage.getItem(STORAGE_KEYS.ENTRANCE);

export const clearEntrancePoint = (): void => localStorage.removeItem(STORAGE_KEYS.ENTRANCE);

export const showChatInfo = (): boolean => localStorage.getItem(STORAGE_KEYS.CHATINFO) !== DISABLE;

export const disableChatInfo = (): void => localStorage.setItem(STORAGE_KEYS.CHATINFO, DISABLE);

export const hasMenuShown = (): boolean => getSessionValue(ISMENUOPEN);

export const setIsMenuOpen = (isOpen: boolean): void => setSessionValue(ISMENUOPEN, isOpen);

export const switchIsMenuOpen = (): void => {
  return getSessionValue(ISMENUOPEN)
    ? setSessionValue(ISMENUOPEN, false)
    : setSessionValue(ISMENUOPEN, true);
};

export const removeIsMenuOpen = (): void => sessionStorage.removeItem(ISMENUOPEN);
