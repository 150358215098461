export const errorHints = {
  required: 'Заполните поле',
  dateRangeInvalid: 'Дата начала периода превышает дату окончания',
  numbersOnly: 'Вводить можно только цифры',
  latinLettersOnly: 'Используйте латинские буквы',
  passwordChange: 'Пароли не совпадают',
  uploadWait: 'Дождитесь окончания загрузки',
  emailValid: 'Укажите корректный почтовый адрес',
  langFieldsEmpty: 'Форма не заполнена полностью ни для одного языка',
  phoneInvalid: 'Укажите корректный номер телефона',
  wrongFormat: 'Неверный формат',
  emptyVouchersTable: 'Добавьте ваучеры в реестр',
};

export const isOnlyLatinRegExp = /^[A-Za-z ,.'-]*$/i;
export const passportNumberRegExp = /^[a-zA-Z0-9 -]*$/i;
export const phoneNumberRegExp = /^\+?[\d ()]*$/;
