import { makeAutoObservable } from 'mobx';
import { isVoucherHrIdentifierValid } from '../services/utils/voucher';
import { MAX_REFUND_AMOUNT } from '../consts/app';
import { VoucherStatus, VoucherInterface } from './entities/voucher';
import type { RootStore } from './index';
import { HttpClient } from '../services/api/http-client';
import { NOTIFICATION_TYPE } from './notification-store';
import { formatMoney } from '../services/utils/numbers';
import { openFromResponse } from '../services/utils/axios-response-helpers';

const { REFUNDED_BY_CASH } = VoucherStatus;
export default class UiStore {
  checkedVouchers: Record<string, VoucherInterface> = {};

  items: VoucherInterface[] = [];

  loading = false;

  hrIdentifier = '';

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  setHrIdentifier = (hrIdentifier: string): void => {
    this.hrIdentifier = hrIdentifier;
  };

  handleCheck = (voucher: VoucherInterface, newValue: boolean): void => {
    if (newValue) {
      this.checkedVouchers = {
        ...this.checkedVouchers,
        [voucher.identifier]: voucher,
      };
    } else {
      this.checkedVouchers = Object.keys(this.checkedVouchers).reduce(
        (acc: Record<string, VoucherInterface>, cur) => {
          if (voucher.identifier !== cur) {
            acc[cur] = voucher;
          }
          return acc;
        },
        {},
      );
    }
  };

  addVoucherToList = async (
    hrIdentifier: string,
    onSuccess?: () => void,
    onFailure?: () => void,
  ) => {
    if (
      this.rootStore.currentUser.currentRefundOffice?.status === 'not_active' ||
      this.rootStore.currentUser.companyGroup?.status === 'not_active'
    ) {
      return;
    }
    this.loading = true;
    try {
      const data = await HttpClient.getVoucherByHrIdentifier(hrIdentifier);
      if (!data) {
        this.rootStore.notification.open(
          'Такого ваучера не существует в системе',
          NOTIFICATION_TYPE.error,
          [],
        );
        onFailure?.();
      } else if (this.items.find((voucher) => voucher.identifier === data.identifier)) {
        this.rootStore.notification.open(
          'Ваучер уже добавлен в список',
          NOTIFICATION_TYPE.warning,
          [],
        );
        onFailure?.();
      } else if (data.voucherRegistries?.some((registry) => registry.type === 'not_paid')) {
        this.rootStore.notification.open(
          'Выплата средств наличными невозможна',
          NOTIFICATION_TYPE.warning,
          ['Данная форма Такс Фри уже представлена к возмещению безналичным способом'],
        );
        onFailure?.();
      } else {
        this.items = [...this.items, data];
        this.hrIdentifier = '';
        onSuccess?.();
      }
    } catch (e) {
      this.rootStore.notification.handleResponseError(e);
      onFailure?.();
    } finally {
      this.loading = false;
    }
  };

  deleteVoucherFromList = (identifier: string): void => {
    this.items = this.items.filter((voucher) => voucher.identifier !== identifier);
    delete this.checkedVouchers[identifier];
  };

  handlePay = async (): Promise<void> => {
    this.loading = true;
    try {
      const { data } = await HttpClient.postAxios('/voucher_receipts', {
        refundOffice: this.rootStore.currentUser.currentRefundOffice?.id,
        vouchers: Object.keys(this.checkedVouchers),
      });
      await Promise.all(
        Object.keys(this.checkedVouchers).map((item) => {
          return HttpClient.patchAxios(`/vouchers/${item}`, {
            status: REFUNDED_BY_CASH,
            refundOffice: this.rootStore.currentUser.currentRefundOffice?.id,
          });
        }),
      );
      openFromResponse(
        await HttpClient.getAxios(`/voucher_receipts/${data.id}/generate`, {
          responseType: 'blob',
        }),
      );
      this.items = this.items.reduce((acc: VoucherInterface[], cur) => {
        if (!this.checkedVouchers[cur.identifier]) {
          acc.push(cur);
        }
        return acc;
      }, []);
      this.rootStore.notification.open('Ваучеры оплачены', NOTIFICATION_TYPE.success, [
        `Сумма ${formatMoney(this.totalRefundableAmount, { divideByHundred: true })}`,
      ]);
      this.checkedVouchers = {};
    } catch (e) {
      HttpClient.log(e, {
        component: 'vouchers-refund-store',
        functionName: 'handlePay',
        codeLine: 134,
        request: {
          refundOffice: this.rootStore.currentUser.currentRefundOffice?.id,
          vouchers: Object.keys(this.checkedVouchers),
        },
        comments: JSON.stringify({
          url1: 'POST: /voucher_receipts',
          url2: `PATCH: /vouchers/ ...many items`,
          url3: `GET: /voucher_receipts/ ...data.id/generate`,
        }),
      });
      this.rootStore.notification.handleResponseError(e);
    } finally {
      this.loading = false;
    }
  };

  clearList = (): void => {
    this.items = [];
    this.hrIdentifier = '';
    this.checkedVouchers = {};
    this.loading = false;
  };

  changeVouchers = (voucher: VoucherInterface): void => {
    this.items = this.items.map((item) => {
      if (item.identifier === voucher.identifier) {
        return voucher;
      }
      return item;
    });
    if (this.checkedVouchers[voucher.identifier]) {
      if (+voucher.refundableAmount > MAX_REFUND_AMOUNT) {
        this.handleCheck(voucher, false);
      } else {
        this.checkedVouchers[voucher.identifier] = voucher;
      }
    }
  };

  isVoucherConsumerPassportValid = (voucher: VoucherInterface): boolean => {
    if (!this.mainVoucher) {
      return true;
    }
    return (
      voucher.consumer.passport?.series === this.mainVoucher.consumer.passport?.series &&
      voucher.consumer.passport?.number === this.mainVoucher.consumer.passport?.number &&
      voucher.consumer.passport?.country.id === this.mainVoucher.consumer.passport?.country.id
    );
  };

  get totalRefundableAmount(): number {
    return Object.values(this.checkedVouchers).reduce((acc: number, cur) => {
      return acc + Number(cur.refundableAmount);
    }, 0);
  }

  get isSomeChecked(): boolean {
    return Object.values(this.checkedVouchers).length > 0;
  }

  get hrIdentifierValid(): boolean {
    return isVoucherHrIdentifierValid(this.hrIdentifier);
  }

  get hrIdentifierExist(): boolean {
    return !!this.items.find((item) => item.hrIdentifier === this.hrIdentifier);
  }

  get mainVoucher(): VoucherInterface | null {
    return this.items[0] || null;
  }
}
