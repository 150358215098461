import { ISelectOption } from '@sberbusiness/triplex/components/Select/Select';

export interface FDataUpdaterType {
  name: FILTERS_NAMES;
  value: ISelectOption | boolean | string;
}

export type FDataUpdaterFoo = (
  name: FILTERS_NAMES,
  value: ISelectOption | boolean | string,
) => void;

export enum CHAT_STATUSES {
  ALL_QUERY = '',
  ACTIVE_CHATS_ONLY = 'activeChatsOnly',
  EXCEPT_ACTIVE_CHATS = 'exceptActiveChats',
}

const { ALL_QUERY, ACTIVE_CHATS_ONLY, EXCEPT_ACTIVE_CHATS } = CHAT_STATUSES;

export enum FILTERS_NAMES {
  STATUS = 'status',
  NEWMESSAGES = 'hasNewMessages',
  ONLYMYQUOTE = 'onlyMy',
  SHOWCLOSING = 'withClosed',
  SWIFT = 'swift',
  QUOTE_ID = 'quoteId',
  OPERATION = 'operation',
  COUNTRY = 'country',
  SBERCLIENT = 'sberClient',
}

export const statusOptions: ISelectOption[] = [
  { value: ALL_QUERY, label: 'Все' },
  { value: ACTIVE_CHATS_ONLY, label: 'Активные чаты' },
  { value: EXCEPT_ACTIVE_CHATS, label: 'Заявки без чатов' },
];

export enum SberClientsOptionsLIST {
  ALL_Q = '', //  Все заявки - по умолчанию
  NO_SBER_Q = 'noFlag', // Заявки без тега
  SBER_CLIENT_Q = 'sber', // Заявки с тегом
  FORMER_SBER_Q = 'notSber', // Заявки со снятым тегом
}

const { SBER_CLIENT_Q, FORMER_SBER_Q, NO_SBER_Q, ALL_Q } = SberClientsOptionsLIST;

export const SberClientsOptions: ISelectOption[] = [
  { value: ALL_Q, label: 'Все заявки' },
  { value: SBER_CLIENT_Q, label: 'Заявки с тегом' },
  { value: FORMER_SBER_Q, label: 'Заявки со снятым тегом' },
  { value: NO_SBER_Q, label: 'Заявки без тега' },
];

export type FiltersDataType = {
  [FILTERS_NAMES.STATUS]: ISelectOption;
  [FILTERS_NAMES.NEWMESSAGES]: boolean;
  [FILTERS_NAMES.ONLYMYQUOTE]: boolean;
  [FILTERS_NAMES.SHOWCLOSING]: boolean;
  [FILTERS_NAMES.SWIFT]: string;
  [FILTERS_NAMES.QUOTE_ID]: string;
  [FILTERS_NAMES.OPERATION]: string;
  [FILTERS_NAMES.COUNTRY]: string;
  [FILTERS_NAMES.SBERCLIENT]: ISelectOption;
};

export const filtersInitData: FiltersDataType = {
  [FILTERS_NAMES.STATUS]: statusOptions[0],
  [FILTERS_NAMES.NEWMESSAGES]: false,
  [FILTERS_NAMES.ONLYMYQUOTE]: false,
  [FILTERS_NAMES.SHOWCLOSING]: false,
  [FILTERS_NAMES.SWIFT]: '',
  [FILTERS_NAMES.QUOTE_ID]: '',
  [FILTERS_NAMES.OPERATION]: '',
  [FILTERS_NAMES.COUNTRY]: '',
  [FILTERS_NAMES.SBERCLIENT]: SberClientsOptions[0],
};
