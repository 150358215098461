import { FC } from 'react';
import { Formik } from 'formik';
import ButtonLink from '@common/ButtonLink';
import Tooltip from '@common/Tooltip';
import LogoWrapper from '@common/LogoWrapper';
import rootStore from '@store/index';
import { LoginErrorType } from '@store/entities/users';
import { useHistory } from 'react-router-dom';
import { HOME_PATH } from '@consts/app';
import styles from './styles.module.scss';
import LoginForm from './LoginForm';
import LoginError from './LoginError';

interface Form {
  login: string;
  password: string;
}

const initialValues: Form = {
  login: '',
  password: '',
};

const Login: FC = () => {
  const history = useHistory();
  const onSubmit = async (formData: Form) => {
    const { login, password } = formData;
    await rootStore.currentUser.linkLogin(login, password);
    history.push(`${HOME_PATH}`);
  };

  // TAXFREE  TAXFREE  TAXFREE  TAXFREE  TAXFREE  TAXFREE  TAXFREE  TAXFREE
  // TAXFREE  TAXFREE  TAXFREE  TAXFREE  TAXFREE  TAXFREE  TAXFREE  TAXFREE
  // TAXFREE  TAXFREE  TAXFREE  TAXFREE  TAXFREE  TAXFREE  TAXFREE  TAXFREE

  return (
    <div className={styles.pageContainer}>
      <Formik<Form> onSubmit={onSubmit} initialValues={initialValues}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={styles.loginForm}>
            <LogoWrapper />
            {rootStore.currentUser.loginError !== LoginErrorType.NONE && (
              <div className={styles.alertWrapper}>
                <LoginError />
              </div>
            )}
            <LoginForm />
            <Tooltip
              preferPlace="below"
              toggleType="click"
              tooltip="Для восстановления пароля обратитесь к вашему администратору"
              containerClassName={styles.forgotButton}
              customTarget={<ButtonLink>Забыли пароль?</ButtonLink>}
            />
          </form>
        )}
      </Formik>
    </div>
  );
};

export default Login;
