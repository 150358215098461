import GoogleMapReact from 'google-map-react';
import { Option, Lang } from '../store/entities/common';

export const MIN_TOTAL_AMOUNT = 1000000; // минимальная сумма в копейках покупки, 10к рублей
export const MAX_REFUND_AMOUNT = 6000000; // максимальная сумма возврата в копейках, 60к рублей
export const MENU_COLLAPSE_BREAKPOINT = 1200; // при этой ширине экрана меню становится выдвижным
export const APP_BAR_HEIGHT = 60;

export const REFUND_OFFICE_DISABLED_TOOLTIP = 'Пункт возврата не активен';
export const ISSUE_POINT_DISABLED_TOOLTIP = 'Пункт выписки не активен';
export const ISSUE_POINT_DISABLED_TOOLTIP_SETTINGS =
  'Выписка ваучера ограничена настройками выбранного пункта выписки';
export const COMPANY_GROUP_DISABLED_TOOLTIP = 'Холдинг не активен';
export const SUPPORT_EMAIL = 'ort@sbertaxfree.ru';
export const BREAKPOINTS = {
  // Должно быть сопоставление с scss переменными из assets/styles/partials/_variables.scss
  XS: 0,
  SM: 768,
  MD: 1024,
  LG: 1225,
  XL: 1920,
};
export const HOME_PATH = '/home'; // нужна константа, иначе из хука перерендеривается

const titleCol = 3;
const controlMd = 5;
const totalColumns = 12;

export const FORM_GRID_PROPS = {
  titleSm: titleCol,
  controlSm: totalColumns - titleCol,
  titleMd: titleCol,
  controlMd,
  controlOffsetRight: {
    md: totalColumns - titleCol - controlMd,
  },
};

export const VIEW_GRID_PROPS_TITLE = {
  xs: 4,
  sm: titleCol,
};
export const VIEW_GRID_PROPS_VALUE = {
  xs: 8,
  sm: 12 - titleCol,
};
export const PHONE_MASK = (rawValue: string) => {
  const mask = [' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  if (rawValue.startsWith('8')) {
    return ['8', ...mask];
  }
  if (rawValue.startsWith('7')) {
    return ['7', ...mask];
  }
  return ['+', '7', ...mask];
};
export const VOUCHER_MASK = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
];
export const CARD_NUMBER_MASK = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
export const IMAGE_EXTENSIONS = ['png', 'jpg', 'svg', 'jpeg'];

export const LANGUAGE_MAP: Record<Lang, string> = {
  // Если добавиться язык, достаточно сда добавить перевод с правильным ключом
  en: 'Английский',
  ru: 'Русский',
  zh: 'Китайский',
  ar: 'Арабский',
};

export const LANGUAGES_KEYS = Object.keys(LANGUAGE_MAP) as Lang[];

export const LANGUAGES = Object.keys(LANGUAGE_MAP).map((item) => ({
  value: item,
  label: LANGUAGE_MAP[item as Lang],
}));

export const requiredFormLang: Lang = 'en';
export const TRANSLATION_PARAMS = {
  groups: ['translations'],
};
export const TRANSLATION_LOCALE = 'ru';
export const DEFAULT_MAP_CENTER: GoogleMapReact.Coords = { lat: 55.75222, lng: 37.61556 };

export const boolOptions: Option[] = [
  {
    label: 'Да',
    value: true,
  },
  {
    label: 'Нет',
    value: false,
  },
];

// при ставке ндс в 0% дизейблим айтем
export const vatForDisable = new Set([5, 6]);

export const refundOptions = [
  { value: '1', label: 'да' },
  { value: '0', label: 'нет' },
];
