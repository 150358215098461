import { FC } from 'react';
import { Spinner } from '@sbbol/web-library/desktop/components/Spinner/Spinner';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface Props {
  className?: string;
}

const SpinnerIcon: FC<Props> = (props) => {
  const { className } = props;
  return (
    <div className={classNames(styles.spinner, className)}>
      <Spinner />
    </div>
  );
};

export default SpinnerIcon;
