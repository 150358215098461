import SegmentedControlSingle, { Props } from './SegmentedControlSingle';
import withFormField from '../withFormField';

export type SegmentedControlSingleProps = Props;
export const SegmentedControlSingleFormField = withFormField<
  SegmentedControlSingleProps,
  SegmentedControlSingleProps['onChange'],
  SegmentedControlSingleProps['value']
>(SegmentedControlSingle);
export default SegmentedControlSingle;
