import { FC } from 'react';
import Logo from '@common/Logo/Logo';
import styles from './styles.module.scss';

const LogoWrapper: FC = () => {
  return (
    <div className={styles.logoWrapper}>
      <Logo />
    </div>
  );
};

export default LogoWrapper;
