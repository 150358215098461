import { ComponentType, useCallback, useState, useMemo } from 'react';
import SegmentedControlSingle from '@common/SegmentedControlSingle';
import { Lang } from '@store/entities/common';
import { LANGUAGES, requiredFormLang } from '@consts/app';
import styles from './styles.module.scss';
import useCopyValue from '../../../hooks/useCopyValue';

function withMultiLangSelect<Props = any>(Component: ComponentType<Props>) {
  return (props: Props) => {
    const { value, onChange, ...rest } = props as any;
    const [lang, setLang] = useState<Lang>('en');
    const valueRef = useCopyValue(value);
    const langRef = useCopyValue(lang);

    const handleChangeLang = useCallback((lang) => {
      setLang(lang);
    }, []);

    const handleChange = useCallback(
      (innerValue, params) => {
        onChange(
          {
            ...(valueRef.current || {}),
            [langRef.current]: innerValue,
          },
          params,
        );
      },
      [onChange, valueRef, langRef],
    );

    const options = useMemo(() => {
      return LANGUAGES.map((item) => {
        return {
          ...item,
          label: item.value,
        };
      });
    }, []);

    const buttonClassNameFunc = useCallback(
      (customId) => {
        if (customId && customId !== requiredFormLang && !value?.[customId]) {
          return styles.warning;
        }
        if (customId && customId === requiredFormLang && !value?.[customId]) {
          return styles.error;
        }
        return '';
      },
      // именно value, не valueRef
      [value],
    );

    return (
      <div>
        <SegmentedControlSingle
          className={styles.languageSelector}
          options={options}
          value={lang}
          onChange={handleChangeLang}
          buttonClassNameFunc={buttonClassNameFunc}
          shrink
          inline
        />
        <Component value={value?.[lang] || ''} onChange={handleChange} {...rest} />
      </div>
    );
  };
}

export default withMultiLangSelect;
