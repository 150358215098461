const ENV = process.env;

const config = {
  isProduction: ENV.REACT_APP_STAND === 'prod',
  isDevelopment: ENV.NODE_ENV === 'development',
  prefix: ENV.REACT_APP_API_PREFIX || '/api/v1',
  host: ENV.REACT_APP_API_HOST,
  hostFns: ENV.REACT_APP_FNS_HOST,
  stand: ENV.REACT_APP_STAND,
  dadataApiKey: ENV.REACT_APP_DADATA_API_KEY || '',
  googleMapApiKey: ENV.REACT_APP_GOOGLE_MAP_KEY || '',
  endlessToken: ENV.REACT_APP_ENDLESS_TOKEN || '',
};

export default config;
