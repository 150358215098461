import { makeAutoObservable } from 'mobx';
import { EOrderDirection } from '@sbbol/web-library/desktop/components/Tables/TableBasic/enums';
import { ISortOrder } from '@sbbol/web-library/desktop/components/Tables/TableBasic/types';
import type { RootStore } from './index';
import { IPagination, ISort, Sections } from './entities/table';
import { HttpClient } from '../services/api/http-client';
import CancelableRequest from '../services/CancelableRequest';
import { NOTIFICATION_TYPE, TITLE_ERROR } from './notification-store';

const defaultRowLimit = 10;

const defaultPagination: IPagination = {
  rowNumberOptions: [defaultRowLimit, 25, 50],
  total: 0,
  rowLimit: defaultRowLimit,
  currentPageNumber: 1,
};

const defaultSort: ISort = {
  createdAt: EOrderDirection.DESC,
  totalAmount: EOrderDirection.NONE,
  taxationAmount: EOrderDirection.NONE,
  refundableAmount: EOrderDirection.NONE,
  id: EOrderDirection.NONE,
  number: EOrderDirection.NONE,
};

export default class TableStore {
  sections: Sections = {
    paymentOrders: {
      sort: { ...defaultSort },
      pagination: { ...defaultPagination },
      loading: false,
    },
    companies: {
      sort: { ...defaultSort },
      pagination: { ...defaultPagination },
      loading: false,
    },
    users: {
      sort: {
        ...defaultSort,
        'userInfo.name': EOrderDirection.NONE,
        username: EOrderDirection.NONE,
      },
      pagination: { ...defaultPagination },
      loading: false,
    },
    cashiersRefund: {
      sort: {
        ...defaultSort,
        'userInfo.name': EOrderDirection.NONE,
        username: EOrderDirection.NONE,
      },
      pagination: { ...defaultPagination },
      loading: false,
    },
    cashiersIssuer: {
      sort: {
        ...defaultSort,
        'userInfo.name': EOrderDirection.NONE,
        username: EOrderDirection.NONE,
      },
      pagination: { ...defaultPagination },
      loading: false,
    },
    fiscalAccumulator: {
      sort: { ...defaultSort, 'company.name': EOrderDirection.NONE },
      pagination: { ...defaultPagination },
      loading: false,
    },
    vouchersCashierPaid: {
      sort: { ...defaultSort },
      pagination: { ...defaultPagination },
      loading: false,
    },
    registry: {
      sort: { ...defaultSort },
      pagination: { ...defaultPagination },
      loading: false,
    },
    registryToPay: {
      sort: { ...defaultSort },
      pagination: { ...defaultPagination },
      loading: false,
    },
    registryNotPaid: {
      sort: { ...defaultSort },
      pagination: { ...defaultPagination },
      loading: false,
    },
    registryCashPaid: {
      sort: { ...defaultSort },
      pagination: { ...defaultPagination },
      loading: false,
    },
    registrySendPaidByCash: {
      sort: { ...defaultSort },
      pagination: { ...defaultPagination },
      loading: false,
    },
    registrySendNotPaid: {
      sort: { ...defaultSort },
      pagination: { ...defaultPagination },
      loading: false,
    },
    registryProcessingPaidByCash: {
      sort: { ...defaultSort },
      pagination: { ...defaultPagination },
      loading: false,
    },
    registryProcessingNotPaid: {
      sort: { ...defaultSort },
      pagination: { ...defaultPagination },
      loading: false,
    },
    logisticRegistries: {
      sort: { ...defaultSort, 'shipment.number': EOrderDirection.NONE },
      pagination: { ...defaultPagination },
      loading: false,
    },
    companyGroups: {
      sort: { ...defaultSort, name: EOrderDirection.NONE },
      pagination: { ...defaultPagination },
      loading: false,
    },
    issuers: {
      sort: {
        ...defaultSort,
        name: EOrderDirection.NONE,
        address: EOrderDirection.NONE,
      },
      pagination: { ...defaultPagination },
      loading: false,
    },
    refunds: {
      sort: {
        ...defaultSort,
        name: EOrderDirection.NONE,
        address: EOrderDirection.NONE,
      },
      pagination: { ...defaultPagination },
      loading: false,
    },
    vouchers: {
      sort: {
        ...defaultSort,
        'company.name': EOrderDirection.NONE,
        hrIdentifier: EOrderDirection.NONE,
      },
      pagination: { ...defaultPagination },
      loading: false,
    },
    paymentHistory: {
      sort: { ...defaultSort, createdAt: EOrderDirection.NONE, amount: EOrderDirection.NONE },
      pagination: { ...defaultPagination },
      loading: false,
    },
    brands: {
      sort: { ...defaultSort, priority: EOrderDirection.NONE },
      pagination: { ...defaultPagination },
      loading: false,
    },
    shops: {
      sort: { ...defaultSort },
      pagination: { ...defaultPagination },
      loading: false,
    },
    customsOffices: {
      sort: { ...defaultSort },
      pagination: { ...defaultPagination },
      loading: false,
    },
    refundOffices: {
      sort: { ...defaultSort },
      pagination: { ...defaultPagination },
      loading: false,
    },
    mailboxes: {
      sort: { ...defaultSort },
      pagination: { ...defaultPagination },
      loading: false,
    },
    cities: {
      sort: { ...defaultSort },
      pagination: { ...defaultPagination },
      loading: false,
    },
    integrations: {
      sort: {
        ...defaultSort,
        accessExpired: EOrderDirection.NONE,
        refreshExpired: EOrderDirection.NONE,
      },
      pagination: { ...defaultPagination },
      loading: false,
    },
  };

  constructor(private rootState: RootStore) {
    makeAutoObservable(this);
  }

  fetchingData = CancelableRequest.create(
    async (source, url: string | undefined, section: keyof Sections, params: any) => {
      if (!url) {
        this.rootState.notification.open(TITLE_ERROR.error, NOTIFICATION_TYPE.error, ['']);
        return Promise.reject(new Error('error'));
      }
      try {
        this.setLoadingSection(section, true);
        const response = await HttpClient.getList(url, {
          cancelToken: source.token,
          params,
        });
        this.setLoadingSection(section, false);
        this.setTotalSection(section, response.total);
        return Promise.resolve(response.data);
      } catch (e) {
        if (CancelableRequest.isCancelledByOuterCall(e)) {
          this.setLoadingSection(section, false);
        } else if (!CancelableRequest.isCancelledByNextRequest(e)) {
          this.rootState.notification.handleResponseError(e);
          this.setLoadingSection(section, false);
        } else {
          HttpClient.log(e, {
            component: 'table-store',
            functionName: 'fetchingData',
            codeLine: 223,
            requestUrl: url,
            request: params,
            method: 'GET',
          });
        }
      }
      return [];
    },
  );

  setTotalSection = (section: keyof Sections, total: number | string) => {
    if (!this.sections[section]?.pagination) {
      return;
    }
    this.sections[section].pagination.total = Number(total);
  };

  handleOrderBySection = (section: keyof Sections, sort: ISortOrder) => {
    if (!this.sections[section]?.sort) {
      return;
    }
    this.sections[section].sort = Object.keys(this.sections[section].sort).reduce(
      (acc: any, cur) => {
        if (cur === sort.fieldKey) {
          acc[cur] = sort.direction;
        } else {
          acc[cur] = EOrderDirection.NONE;
        }
        return acc;
      },
      {},
    );
  };

  getCurrentSortSection = (section: keyof Sections): ISortOrder | null => {
    const foundKey = Object.keys(this.sections[section]?.sort || {})?.find(
      (key) => this.sections[section].sort[key] !== EOrderDirection.NONE,
    );
    if (foundKey) {
      return {
        fieldKey: foundKey,
        direction: this.sections[section].sort[foundKey],
      };
    }
    return null;
  };

  setCurrentLimitSection = (section: keyof Sections, limit: number) => {
    if (!this.sections[section]?.pagination) {
      return;
    }
    const maxPage = Math.ceil(this.sections[section].pagination.total / limit);
    if (this.sections[section].pagination.currentPageNumber > maxPage) {
      this.sections[section].pagination.currentPageNumber = maxPage;
    }
    this.sections[section].pagination.rowLimit = limit;
  };

  setCurrentPageSection = (section: keyof Sections, currentPage: number) => {
    if (!this.sections[section]?.pagination) {
      return;
    }
    this.sections[section].pagination.currentPageNumber = currentPage;
  };

  onClickPrevPageSection = (section: keyof Sections) => {
    if (!this.sections[section]?.pagination) {
      return;
    }
    this.sections[section].pagination.currentPageNumber--;
  };

  onClickNextPageSection = (section: keyof Sections) => {
    if (!this.sections[section]?.pagination) {
      return;
    }
    this.sections[section].pagination.currentPageNumber++;
  };

  hasNextPageSection = (section: keyof Sections) => {
    if (
      !this.sections[section]?.pagination ||
      this.sections[section].pagination.total <= this.sections[section].pagination.rowLimit
    ) {
      return false;
    }
    return (
      this.sections[section].pagination.currentPageNumber + 1 <=
      Math.ceil(
        this.sections[section].pagination.total / this.sections[section].pagination.rowLimit,
      )
    );
  };

  hasPrevPageSection = (section: keyof Sections) => {
    if (!this.sections[section]?.pagination) {
      return false;
    }
    return this.sections[section].pagination.currentPageNumber > 1;
  };

  resetPaginationSection = (section: keyof Sections) => {
    if (!this.sections[section]?.pagination) {
      return;
    }
    this.sections[section].pagination.currentPageNumber = 1;
  };

  setLoadingSection = (section: keyof Sections, value: boolean) => {
    if (!this.sections[section]) {
      return;
    }
    this.sections[section].loading = value;
  };

  hasPaginationSection = (section: keyof Sections) => {
    if (!this.sections[section]?.pagination) {
      return false;
    }
    return (
      this.sections[section].pagination.total >
      this.sections[section].pagination.rowNumberOptions[0]
    );
  };
}
