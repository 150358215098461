import { Route, Switch, Redirect } from 'react-router-dom';
import AppNotification from '@common/Notification';
import { FC, Suspense } from 'react';
import { Spinner } from '@sbbol/web-library/desktop/components/Spinner/Spinner';
import useWindowWidth from '@hooks/useWindowWidth';
import { HOME_PATH } from '@consts/app';
import lazyWithRetry from '@services/utils/lazyWithRetry';
import ErrorBoundary from '@components/ErrorBoundary';
import ProtectedRoute from '@containers/views/router/protected-route';
import Alert from '@containers/views/app/modals/Alert';
import Confirm from '@containers/views/app/modals/Confirm';
import LoginRoute from '@containers/views/router/login-route';
import Login from '@containers/Login';
import { LINK_PATH } from '@link/LData/lData';
import '@sberbusiness/triplex/styles/styles.css';

const CheckScanner = lazyWithRetry(() => import('@containers/roles/cashier/CheckScanner'));
const ChangePassword = lazyWithRetry(() => import('@containers/ChangePassword'));
const SwitcherRoles = lazyWithRetry(() => import('@containers/SwitcherRoles'));
const ClientSubscription = lazyWithRetry(
  () => import('./components/clientSubscription/ClientSubscription'),
);
const ClientProcess = lazyWithRetry(() => import('@components/clientProcess/ClientProcess'));
const LinkLogin = lazyWithRetry(() => import('@link/LinkLogin'));
const ManagerPage = lazyWithRetry(() => import('@containers/LINK/ManagerPage/ManagerModal'));

const App: FC = () => {
  useWindowWidth();
  return (
    <>
      <ErrorBoundary>
        <Alert />
        <Confirm />
        <AppNotification />
        <Switch>
          <Route path={LINK_PATH}>
            <Suspense fallback={<Spinner />}>
              <LinkLogin />
            </Suspense>
          </Route>
          <Route path="/km">
            <Suspense fallback={<Spinner />}>
              <ManagerPage />
            </Suspense>
          </Route>
          <Route path="/client-subscription">
            <Suspense fallback={<Spinner />}>
              <ClientSubscription />
            </Suspense>
          </Route>
          <Route path="/client-process">
            <Suspense fallback={<Spinner />}>
              <ClientProcess />
            </Suspense>
          </Route>
          <Route path="/change-password">
            <Suspense fallback={<Spinner />}>
              <ChangePassword />
            </Suspense>
          </Route>
          <Route path="/check_scanner">
            <Suspense fallback={<Spinner />}>
              <CheckScanner />
            </Suspense>
          </Route>
          <LoginRoute exact path="/login">
            <Login />
          </LoginRoute>
          <ProtectedRoute path={HOME_PATH}>
            <Suspense fallback={<Spinner />}>
              <SwitcherRoles />
            </Suspense>
          </ProtectedRoute>
          <Route path="*">
            <Redirect to={HOME_PATH} />
          </Route>
        </Switch>
      </ErrorBoundary>
    </>
  );
};

export default App;
