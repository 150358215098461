import { Tooltip as SbBolTooltip } from '@sbbol/web-library/desktop/components/Tooltip/Tooltip';
import { TooltipBody } from '@sbbol/web-library/desktop/components/Tooltip/TooltipBody';
import { TooltipTarget } from '@sbbol/web-library/desktop/components/Tooltip/TooltipTarget';
import ButtonIcon from '@common/ButtonIcon';
import { ETooltipSize } from '@sbbol/web-library/desktop/components/Tooltip/enums';
import { QuestionIcon16 } from '@sbbol/icons/QuestionIcon16';
import { FC, ReactNode, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { ITooltipProps } from '@sbbol/web-library/desktop/components/Tooltip/types';
import styles from './styles.module.scss';

interface Props extends Pick<ITooltipProps, 'toggleType' | 'preferPlace'> {
  tooltip: any;
  label?: ReactNode;
  containerClassName?: string;
  labelClassName?: string;
  customTarget?: JSX.Element;
  align?: 'left' | 'center' | 'right';
  underlineOnHover?: boolean;
  size?: ETooltipSize;
}

const Tooltip: FC<Props> = (props) => {
  const {
    tooltip,
    label,
    toggleType = 'hover',
    preferPlace = 'above',
    containerClassName: containerClassNameProp,
    labelClassName,
    customTarget,
    align = 'left',
    underlineOnHover,
    size = ETooltipSize.SM,
  } = props;
  const ref = useRef<HTMLButtonElement | null>(null);

  let target = customTarget;
  if (!target) {
    target = (
      <ButtonIcon ref={ref}>
        <QuestionIcon16 />
      </ButtonIcon>
    );
  }
  const tooltipComponent = (
    <SbBolTooltip size={size} toggleType={toggleType} preferPlace={preferPlace}>
      <TooltipBody>{tooltip}</TooltipBody>
      <TooltipTarget>{target}</TooltipTarget>
    </SbBolTooltip>
  );

  const containerClassName = classnames(
    styles.container,
    {
      [styles.containerUnderline]: underlineOnHover,
      [styles.containerLeft]: align === 'left',
      [styles.containerCenter]: align === 'center',
      [styles.containerRight]: align === 'right',
    },
    containerClassNameProp,
  );

  useEffect(() => {
    if (ref.current) {
      ref.current.setAttribute('tabindex', '-1');
    }
  }, []);

  if (label) {
    return (
      <div className={containerClassName}>
        <div className={classnames(styles.label, labelClassName)}>{label}</div>
        {tooltipComponent}
      </div>
    );
  }
  return <div className={containerClassName}>{tooltipComponent}</div>;
};

export default Tooltip;
