import { QUOTE_STATUS_TYPE } from '@link/LData/lEnums';
import { SberClientsOptionsLIST } from '@link/LData/lFilterData';

export const enterValue = 'Введите значение';
export const companyNamePlaceholder = 'Введите наименование';
export const innPlaceholder = 'Введите ИНН';
export const emailPlaceholder = 'Введите E-mail';
export const namePlaceholder = 'Введите Ф.И.О';
export const addClientManager = 'Добавить клиентского менеджера';
export const companyName = 'Название компании';
export const emailText = 'Электронная почта';
export const clientManager = 'Клиентский менеджер';
export const agentRating = 'Рейтинг агента';
export const clientRating = 'Рейтинг клиента';
export const agentPlatform = 'Агент платформы';
export const clientPlatform = 'Клиент платформы';
export const newClient = 'Новый клиент';
export const newAgent = 'Новый агент';
export const editClient = 'Редактирование клиента';
export const editAgent = 'Редактирование агента';
export const aComment = 'Комментарий';
export const enterAComment = 'Введите комментарий';
export const ratingNotSpecified = 'Рейтинг не указан';
export const innNumber = 'ИНН';
export const contractWithAgentTxt = 'Номер договора';
export const noDataText = 'Не указано';
export const saveEnteredData = 'Сохранить';
export const cancelText = 'Отмена';
export const canselAltText = 'Отменить';
export const confirmText = 'Подтвердить';
export const emailTextcompanyText = 'Компания';
export const roleText = 'Роль';
export const createText = 'Создать';
export const loginTEXT = 'Логин';
export const enterEmailTEXT = 'Введите электронную почту';
export const choseRoleText = 'Выберите роль';
export const setUserLogin = 'Укажите логин пользователя';
export const clearFormFields = 'Очистить';

export const clientOfferSelect = {
  offerSelect: 'Выбрать предложение',
  offerReject: 'Отказ от предложений',
};

export const chatConst = {
  loadingWithDots: 'Загрузка...',
  loadingCheck: 'Проверка файла...',
  loadingError: 'Ошибка загрузки',
  loadingErrorCheck: 'Ошибка проверки файла',
  downloadText: 'Скачать',
  deleteText: 'Удалить',
  repeatText: 'Повторить',
};

export enum statusLabels {
  inProgress = 'В работе',
  agentIsSelected = 'выбран агент',
  offerSelected = 'Выбрано предложение',
  anotherOfferSelected = 'Выбрано другое предложение',
  completed = 'Выполнена',
  finished = 'Завершена',
  newRequest = 'Новая заявка',
  waitingForYourResponse = 'ожидание вашего ответа',
  waitingForResponse = 'Ожидание ответа',
  waitingForOffers = 'Ожидание предложений',
  fromClient = 'от клиента',
  cancelled = 'Отменена',
  sentToAgents = 'Отправлено агентам',
  goToChat = 'Перейти в чат',
  openChat = 'Открыть чат',
  paymentCompleted = 'Платёж проведён',
  offersReceived = 'Получены предложения',
  created = 'Создано',
  creatingChata = 'Создание чата',
  moderatorAssignment = 'Назначить модератора',
  moderatorSelection = 'Подбор модератора',
  anotherModerator = 'Заявка у другого модератора',
  allStatuses = 'Все статусы',
}

export const enum filterTabsLabels {
  ALL = 'Все',
  ACTIVE = 'Активные',
  NEW = 'Новые сообщения',
  ARCHIVE = 'Архив',
}

const { COMPLETED, CLOSED } = QUOTE_STATUS_TYPE;

const { FORMER_SBER_Q } = SberClientsOptionsLIST;

export const confirmModalText: Record<string, string> = {
  [CLOSED]: 'Причина отмены заявки',
  [COMPLETED]: 'Вы уверены, что хотите подтвердить проведение платежа?',
  [FORMER_SBER_Q]: 'Вы уверены, что хотите снять тег клиента платформы с заявки?',
};
