import Button from '@common/Button';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { HttpClient } from '../../services/api/http-client';
import styles from './styles.module.scss';

interface State {
  hasError: boolean;
  history?: History;
}

class ErrorBoundary extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  componentDidCatch(e: any, errorInfo: React.ErrorInfo) {
    HttpClient.log(e, {
      component: 'ErrorBoundary.tsx',
      functionName: 'componentDidCatch',
      codeLine: 26,
      comments: e.message + errorInfo.componentStack,
    });
  }

  resetError: () => void = () => {
    const { history } = this.props;
    if (history) history.push('/');
    location.reload();
  };

  render(): React.ReactNode {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <div className={styles.container}>
          <h3 className={styles.title}>Что-то пошло не так...</h3>
          <Button onClick={this.resetError} variant="general">
            Перезагрузить приложение
          </Button>
        </div>
      );
    }

    return children;
  }
}

export default withRouter(ErrorBoundary);
