import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { AlertProcess } from '@sbbol/web-library/desktop/components/Alert/AlertProcess/AlertProcess';
import { EAlertType } from '@sbbol/web-library/desktop/components/Alert/EAlertType';
import rootStore from '@store/index';
import { LoginErrorType } from '@store/entities/users';

const errors: Partial<Record<LoginErrorType, string>> = {
  [LoginErrorType.NONE]: '',
  [LoginErrorType.USER_DISABLED]: 'Эта учетная запись не активна.',
  [LoginErrorType.UNAUTHORIZED]: 'Неверный логин или пароль. Попробуйте ещё раз.',
};

const LoginError: FC = () => {
  return (
    <AlertProcess type={EAlertType.ERROR}>
      {errors[rootStore.currentUser.loginError] ||
        'Неизвестная ошибка, проверьте интернет соединение.'}
    </AlertProcess>
  );
};

export default observer(LoginError);
