import { isEmpty } from 'lodash';
import { formatRubToPenny, roundRub } from './numbers';

export interface IFnsObject {
  fiscalNumber: string;
  fiscalDocumentId: number;
  fiscalSign: string;
  operationType: string;
  sum: string;
  date: string;
}

enum ErrorKey {
  sum = 'sum',
  date = 'date',
  fiscalNumber = 'fiscalNumber',
  operationType = 'operationType',
  fiscalDocumentId = 'fiscalDocumentId',
  fiscalSign = 'fiscalSign',
}

export type IErrorKey = {
  [key in ErrorKey]: string;
};

const errorKeyToRus: IErrorKey = {
  sum: 'Сумма',
  date: 'Дата',
  fiscalNumber: 'Фискальный накопитель',
  operationType: 'Тип операции',
  fiscalDocumentId: 'Порядковый номер фискального документа',
  fiscalSign: 'Фискальный признак документа',
};

export const mappingError = (error: { errors: IErrorKey }) => {
  const result: any = {};
  const { errors } = error;
  if (!isEmpty(errors) && errors) {
    for (const key in errors) {
      if (errors[key as ErrorKey] !== undefined) {
        result[errorKeyToRus[key as ErrorKey]] = [
          `${errorKeyToRus[key as ErrorKey]}:`,
          ...errors[key as ErrorKey],
        ];
      }
    }
  }
  return result;
};

const correctSum = (str: string | undefined) => {
  if (str) {
    const newStr = str.replace(/([а-яёa-zt\/])/i, '.');
    return String(roundRub(newStr));
  }
  return '';
};

export const deleteCharacterInDate = (str?: string): string => {
  if (str) {
    return str.replace(/([a-z]|[а-яё])/gims, '');
  }
  return '';
};

export const convertDate = (str: string): string => {
  const date = [];
  const time = [];
  date.push(str.split('').splice(0, 4).join(''));
  date.push(str.split('').splice(4, 2).join(''));
  date.push(str.split('').splice(6, 2).join(''));
  time.push(str.split('').splice(8, 2).join(''));
  time.push(str.split('').splice(10, 2).join(''));
  return `${date.join('-')} ${time.join(':')}:00`;
};

const parseQrFromDifferentScannerModelToNormalFormat = (str: string): IFnsObject | false => {
  const arr = str.match(/\d+([а-яёa-zt\.\/]\d+)?/gi);
  if (!arr) {
    return false;
  }
  const date = convertDate(deleteCharacterInDate(arr[0]));
  const sum = formatRubToPenny(correctSum(arr[1]));
  const fiscalNumber = arr[2] || '';
  const fiscalDocumentId = Number(arr[3]);
  const fiscalSign = arr[4] || '';
  const operationType = arr[5] || '';
  return { date, sum, fiscalNumber, fiscalDocumentId, fiscalSign, operationType };
};

export const correctObj: IFnsObject = {
  fiscalNumber: '9960440301160536',
  fiscalDocumentId: 130186,
  fiscalSign: '256803524',
  operationType: '1',
  sum: '1200',
  date: '2021-12-01 14:11:00',
};

export default parseQrFromDifferentScannerModelToNormalFormat;
