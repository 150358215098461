import { ButtonHTMLAttributes, DetailedHTMLProps, FC } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

const ButtonLink: FC<
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
> = (props) => {
  const { className, type = 'button', ...rest } = props;
  return <button type={type} className={classNames(styles.button, className)} {...rest} />;
};

export default ButtonLink;
