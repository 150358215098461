import { ComponentType, lazy, LazyExoticComponent } from 'react';
import { getStorageValue, setStorageValue } from './storage';

const key = 'page-has-been-force-refreshed';

function lazyWithRetry<T extends ComponentType<any>>(
  componentImport: () => Promise<{ default: T }>,
): LazyExoticComponent<T> {
  // @ts-ignore
  return lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = getStorageValue(key);

    try {
      const component = await componentImport();

      setStorageValue(key, false);

      return component;
    } catch (e) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        setStorageValue(key, true);
        return window.location.reload();
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw e;
    }
  });
}

export default lazyWithRetry;
