import { ReactElement } from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import store from '@store/index';
import { getExitPoint } from '@services/utils/storage';

const ProtectedRoute = ({ children }: RouteProps): ReactElement => {
  const { hasToken, shouldChangePassword } = store.currentUser;
  const { push } = useHistory();

  if (!hasToken) {
    push(getExitPoint());
  }

  if (shouldChangePassword) {
    push('/change-password');
  }

  return <Route>{children}</Route>;
};

export default observer(ProtectedRoute);
