import { FC, useRef, memo, useMemo, useCallback } from 'react';
import Button from '@common/Button';
import classNames from 'classnames';
import { getOptionsFromAny } from '@services/utils/common';
import styles from './styles.module.scss';

export interface Props {
  labelKey?: string;
  valueKey?: string;
  getOptionLabel?: (option: any) => string;
  options: any[];
  onChange: (value: any) => void;
  value: string;
  shrink?: boolean;
  inline?: boolean;
  className?: string;
  disabled?: boolean;
  buttonClassNameFunc?: (customId?: string) => string;
  buttonActiveClassNameFunc?: (customId?: string) => string;
}

const SegmentedControlSingle: FC<Props> = (props) => {
  const {
    className,
    shrink,
    inline,
    options,
    value,
    valueKey = 'value',
    labelKey = 'label',
    getOptionLabel,
    disabled,
    onChange,
    buttonClassNameFunc,
    buttonActiveClassNameFunc,
  } = props;

  const mappedOptions = useMemo(() => {
    return getOptionsFromAny(options, {
      valueKey,
      labelKey,
      getOptionLabel,
    });
  }, [options, valueKey, labelKey, getOptionLabel]);

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const handleClick = useCallback(
    (_, { customId }) => {
      onChange(customId);
    },
    [onChange],
  );

  return (
    <div
      ref={wrapperRef}
      className={classNames(styles.wrapper, { [styles.wrapperInline]: inline }, className)}>
      {mappedOptions.map((item, index, arr) => {
        let buttonClassNameCalculated = classNames(styles.button, {
          [styles.buttonActive]: item.value === value,
          [styles.buttonShrink]: shrink,
          [styles.buttonFirst]: index === 0,
          [styles.buttonLast]: index === arr.length - 1,
          [styles.buttonInline]: inline,
        });

        if (buttonClassNameFunc) {
          buttonClassNameCalculated = classNames(
            buttonClassNameCalculated,
            buttonClassNameFunc(item.value),
          );
        }

        if (buttonActiveClassNameFunc && item.value === value) {
          buttonClassNameCalculated = classNames(
            buttonClassNameCalculated,
            buttonActiveClassNameFunc(item.value),
          );
        }
        return (
          <Button
            className={buttonClassNameCalculated}
            onClick={handleClick}
            customId={item.value}
            key={item.value}
            type="button"
            disabled={disabled}
            variant={item.value === value ? 'general' : 'secondary'}>
            {item.label}
          </Button>
        );
      })}
    </div>
  );
};

export default memo(SegmentedControlSingle);
