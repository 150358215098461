/* eslint-disable import/no-cycle */
/* eslint-disable class-methods-use-this */
import { makeAutoObservable, toJS } from 'mobx';
import moment from 'moment';
import uniqueId from 'lodash/uniqueId';
import isEqual from 'lodash/isEqual';
import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import config from '@consts/config';
import axios from '@services/axios/axios';
import emptyFunction from '@link/Utils/emptyFunction';
import { HttpClient } from '../services/api/http-client';
import type { RootStore } from './index';
import { parseQRCode, QRCodeType } from '../services/utils/qr-code-parsers';
import { INewVoucherForm, IReceipt } from '../containers/roles/cashier/home/NewVoucherPage/types';
import { formatPennyToRub } from '../services/utils/numbers';
import HandlerObject from '../helpers';
import { initialVouchersFilter } from '../consts/filters';
import { NOTIFICATION_TYPE, TITLE_ERROR } from './notification-store';
import { formatDateToValue } from '../services/utils/dates';
import parseQrFromDifferentScannerModelToNormalFormat, {
  IFnsObject,
  correctObj,
} from '../services/utils/scanner-mapping-qr';
import { OptionExtended } from './entities/common';
import { IssuerPointAPI } from './entities/issuers';
import { PATH } from './entities/table';
import { IsCheckedScanner } from '../consts/scanner';
import {
  initialValueReceipt,
  convertVatToTabObj,
} from '../containers/roles/cashier/home/NewVoucherPage/const';
import { vatForDisable } from '../consts/app';

interface NumericKeysAndStringValues {
  [key: number]: string;
}

export enum ReceiptScanningState {
  PENDING = 'pending',
  RECEIPT_OPENED = 'receiptOpened',
  RECEIPT_ERROR = 'receiptError',
}

export enum ReceiptScanningError {
  EXISTING_RECEIPT,
  INCORRECT_INN,
  INN_DOES_NOT_MATCH,
  DATE_DOES_NOT_MATCH,
  INCORRECT_FISCAL_NUMBER,
  EXISTING_QR_CODE,
  UNKNOWN_ERROR,
  DATE_MORE_3MONTHS,
  DATE_ONE_DATA,
  INCORRECT_TYPE_OPERATION,
  BAD_SCAN_RESULT,
  PARSED_DATA_FAILED,
  FNS_HAS_NO_DATA,
  DATE_OF_FUTURE,
  THERE_IS_NO_FISCAL,
  NO_TAXABLE_GOODS,
}

interface APISuccessFNS {
  code: number;
  fiscalDocumentFormatVer: number;
  requestNumber: number;
  dateTime: number;
  operationType: number;
  appliedTaxationType: number;
  kktRegId: string;
  totalSum: number;
  cashTotalSum: number;
  ecashTotalSum: number;
  prepaidSum: number;
  creditSum: number;
  provisionSum: number;
  fiscalDocumentNumber: number;
  fiscalDriveNumber: string;
  fiscalSign: number;
  messageFiscalSign: string;
  companyName: string;
  companyInn: string;
  companyPlaceAddress: null;
  companyPlace: string;
  nds18: number | null;
  nds10: number | null;
  nds0: number | null;
  ndsNo: number | null;
  nds18118: number | null;
  nds10110: number | null;
  items: APIFnsItems[];
  totalSumOfVatItems: number;
}

interface APIFnsItems {
  price: number;
  quantity: number;
  nds: number;
  sum: number;
  name: string;
  paymentType: number;
  productType: number;
  unit: string | null;
  unitNds: number | null;
  exciseDuty: number | null;
  ndsSum: number;
  productCode: number | null;
  paymentAgentType: number | null;
}

interface MapingDataFNS {
  inn: string;
  issuedAt: string | null;
  operationType?: number;
  fiscalDriveNumber: string;
  totalAmountByFns: string;
  items: any;
  requestNumber: number;
  totalSumOfVatItems: number;
}

const INIT_QR_CODE_STATE = {
  isModalOpen: false,
  result: '',
  type: QRCodeType.OTHER,
  scannedAt: '' as string | null,
  qrCodeCount: 0,
  qrCodeNumber: 0,
  error: null as ReceiptScanningError | null,
};

export default class ScannerStore {
  parsing = false;

  checkingScannerModalIsVisible = true;

  receiptOpened = false;

  scanningStatus: ReceiptScanningState = ReceiptScanningState.PENDING;

  qrCodeState = INIT_QR_CODE_STATE;

  contentCheckScannerModal: IsCheckedScanner = IsCheckedScanner.Default;

  private _unsubscribe: Array<() => any> = [];

  remove: (<T>(index: number) => T | undefined) | undefined = undefined;

  push: ((obj: any) => void) | undefined = undefined;

  setSubmitting: ((isSubmitting: boolean) => void) | undefined = undefined;

  values: INewVoucherForm = {} as INewVoucherForm;

  setFieldValue:
    | ((field: string, value: any, shouldValidate?: boolean | undefined) => void)
    | undefined = undefined;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
    (window as any).cr = rootStore.voucher;
  }

  toggleCheckingScannerModal(value: boolean): void {
    this.checkingScannerModalIsVisible = value;
  }

  openModal(): void {
    this.qrCodeState.isModalOpen = true;
  }

  closeModal(): void {
    this.clear();
  }

  setContentCheckScanModal = (value: IsCheckedScanner): void => {
    this.contentCheckScannerModal = value;
  };

  getContentCheckScanModal = (): IsCheckedScanner => {
    return this.contentCheckScannerModal;
  };

  handleScanChecking = async (scanResult: string): Promise<false | true> => {
    try {
      const parsedQR = parseQrFromDifferentScannerModelToNormalFormat(scanResult);
      if (parsedQR) {
        if (isEqual(parsedQR, correctObj)) {
          const response = await HttpClient.postAxios<IssuerPointAPI>(
            `/${PATH.issuers}/${this.rootStore.currentUser.currentIssuerPoint?.id}/scan`,
            {
              isScanChecked: true,
              isScanCheckSuccess: true,
            },
          );

          if (response?.status === 204) {
            return true;
          }

          throw new Error('#196, Ошибка проверки сканера: отрицательный ответ от сервера');
        } else {
          throw new Error('#198, Ошибка проверки сканера: данные не идентичны тестовым');
        }
      } else {
        throw new Error('#201, Ошибка проверки сканера: данные QR-кода не распознаны');
      }
    } catch (error: any) {
      this.rootStore.notification.handleResponseError(error);
      HttpClient.log(error, {
        component: 'ScannerStore',
        functionName: 'handleScanChecking',
        codeLine: 219,
        method: 'POST',
        requestUrl: `/${PATH.issuers}/${this.rootStore.currentUser.currentIssuerPoint?.id}/scan`,
        request: { scanResult },
        comments: error.message,
      });
      return false;
    }
  };

  handleScan = async (scanned: string, valuesForm?: INewVoucherForm): Promise<void> => {
    this.values = valuesForm || ({} as INewVoucherForm);
    this.setParsing(false);
    const [type, result] = parseQRCode(scanned) || '';
    if (type !== QRCodeType.BASE64 && result) {
      await this.handleQRCodeValue(type, result);
    } else {
      this.closeModal();
    }
  };

  handleScanSearch = async (scanned: string): Promise<void> => {
    this.setParsing(false);
    const [type, result] = parseQRCode(scanned) || '';
    if (type !== QRCodeType.BASE64 && result) {
      await this.handleQRCodeSearching(type, result);
    } else {
      this.closeModal();
    }
  };

  getDateFirstReceipt = (): string | null => {
    return this.values.issuedAt;
  };

  setValues = (values: INewVoucherForm): void => {
    this.values = values;
  };

  unsubscribe(): void {
    this._unsubscribe.forEach((unsubscribe) => unsubscribe());
    this._unsubscribe = [];
  }

  setParsing(value: boolean): void {
    this.parsing = value;
  }

  setScanningError(error: ReceiptScanningError | null): boolean {
    this.scanningStatus = ReceiptScanningState.RECEIPT_ERROR;
    this.qrCodeState.isModalOpen = true;
    this.qrCodeState.error = error;
    return true;
  }

  takeDateFirstReceipt(comingIssuedAt: string | null): boolean {
    const { issuedAt } = this.values || {};
    if (issuedAt) {
      return moment(this.getDateFirstReceipt()).isSame(moment(comingIssuedAt), 'day');
    }
    return true;
  }

  toPreventFutureDate(comingIssuedAt: string | null): boolean {
    return moment().isBefore(moment(comingIssuedAt), 'day');
  }

  isValidInn(): NumericKeysAndStringValues | undefined {
    let result;
    if (this.rootStore.currentUser.currentIssuerPoint?.company) {
      result = this.rootStore.currentUser.currentIssuerPoint?.company.reduce((acc, company) => {
        if (company.companyInfo) {
          return { ...acc, [company.companyInfo?.inn]: company.id };
        }
        return company;
      }, {});
    }
    return result;
  }

  isThereDoubleReceipt(receipt: MapingDataFNS): boolean {
    return (
      this.values.fiscalReceipts.filter(
        ({ inn, issuedAt, fiscalAccumulator, number }) =>
          receipt.inn === inn &&
          receipt.issuedAt === issuedAt &&
          this.findFiscalAccumulatorID(receipt.fiscalDriveNumber) === fiscalAccumulator &&
          String(receipt.requestNumber) === number,
      ).length >= 1
    );
  }

  ifEntityHasChosen(companyId: string, inn: string): boolean {
    if (companyId) {
      const company = this.rootStore.currentUser.currentIssuerPoint?.company.filter((company) => {
        return company.id === companyId && company.companyInfo?.inn === inn;
      });
      if (company) {
        return !!company.length;
      }
    }
    return true;
  }

  clear(): void {
    this.parsing = false;
    this.receiptOpened = false;
    this.scanningStatus = ReceiptScanningState.PENDING;
    this.qrCodeState = INIT_QR_CODE_STATE;
  }

  mappingUnitsFNS = (unit: string | null): number => {
    const filtered = this.rootStore.genericEntities.options.units.find(
      (item) => item.ftsName === unit,
    );
    if (filtered) {
      return filtered.id;
    }
    return 1;
  };

  getCompanyByInn = (inn: string): undefined | string => {
    if (this.rootStore.currentUser.currentIssuerPoint) {
      const company = this.rootStore.currentUser.currentIssuerPoint.company.find(
        (company) => company.companyInfo?.inn === inn,
      );
      if (company) {
        return company.id;
      }
    }
    return undefined;
  };

  setCompanyIdFromQR = (): void => {
    const { inn } = this.values.fiscalReceipts[this.values.fiscalReceipts.length - 1] || {};
    if (inn) {
      if (Object.keys(this.isValidInn() as {}).includes(inn)) {
        const id = this.getCompanyByInn(inn);
        if (id && this.setFieldValue) {
          this.setFieldValue('company', id);
        }
      }
    }
  };

  setDateFromQR = (): void => {
    const resultDate = this.values.fiscalReceipts.reduce(
      (acc, { issuedAt }) => issuedAt || acc,
      '',
    );

    if (resultDate && this.setFieldValue) {
      this.setFieldValue('issuedAt', resultDate);
    }
  };

  deleteNotTouchedFirstReceipt = (): void => {
    if (isEqual(toJS(this.values.fiscalReceipts[0]), initialValueReceipt)) {
      this.remove?.(0);
    }
  };

  QRCodeSearching = async (values: string): Promise<void> => {
    try {
      this.rootStore.tableStore.setLoadingSection('vouchers', true);
      const parse = parseQrFromDifferentScannerModelToNormalFormat(values);
      if (parse) {
        const differentInHoursTimeZone = moment(parse.date).utcOffset() / 60;
        this.rootStore.filtersTable.setFilterValue('vouchers', {
          fiscalTotalAmount: parse.sum,
          fiscalAccumulatorNumber: parse.fiscalNumber,
          fiscalIssuedAt: formatDateToValue(moment(parse.date).add(differentInHoursTimeZone, 'h')),
        });
      }
    } catch (e) {
      HttpClient.log(e, {
        component: 'ScannerStore',
        functionName: 'QRCodeSearching',
        codeLine: 390,
        request: values,
      });

      this.rootStore.notification.open(TITLE_ERROR.error, NOTIFICATION_TYPE.error, [
        'Что то пошло не так!',
      ]);
      this.rootStore.filtersTable.setFilterValue('vouchers', initialVouchersFilter);
    }
  };

  findFiscalAccumulatorID = (fiscal: string): string | null => {
    if (toJS(this.rootStore.genericEntities.options.fiscalAccumulatorOptions).length) {
      const result: OptionExtended[] = toJS(
        this.rootStore.genericEntities.options.fiscalAccumulatorOptions,
      ).filter((option) => {
        return option.label === fiscal;
      });
      if (result.length) {
        return result[0].id;
      }
    }
    return null;
  };

  mappingDataFromFNS = (json: APISuccessFNS): MapingDataFNS => {
    return {
      inn: json.companyInn.trim(),
      fiscalDriveNumber: json.fiscalDriveNumber.trim(),
      issuedAt: formatDateToValue(moment.utc(json.dateTime, 'X')),
      totalAmountByFns: String(json.totalSum),
      operationType: json.operationType,
      items: json.items,
      requestNumber: json.requestNumber,
      totalSumOfVatItems: +json.totalSumOfVatItems,
    };
  };

  getDataFNS = async (parsedObj: false | IFnsObject): Promise<MapingDataFNS> => {
    this.setSubmitting?.(true);
    try {
      const obj = await this.parsingQR(parsedObj);
      const data = await this.fetchingDataFNS(obj);
      return await this.isThereDataFNS(data);
    } catch (e) {
      return Promise.reject();
    } finally {
      this.setSubmitting?.(false);
    }
  };

  parsingQR = async (parsedObj: false | IFnsObject): Promise<IFnsObject> => {
    try {
      if (parsedObj) {
        return parsedObj;
      }
      throw new Error();
    } catch (e) {
      this.setScanningError(ReceiptScanningError.BAD_SCAN_RESULT);
      return Promise.reject();
    }
  };

  fetchingDataFNS = async (parsedObj: IFnsObject): Promise<APISuccessFNS> => {
    try {
      if (parsedObj) {
        const { data } = await axios.get(`${config.host}/api/v1/receipt/get-data`, {
          params: {
            ...parsedObj,
          },
        });
        return data;
      }
      throw new Error();
    } catch (e) {
      HttpClient.log(e, {
        method: 'GET',
        component: 'VoucherStore',
        functionName: 'fetchingDataFNS',
        requestUrl: `${config.host}/api/v1/receipt/get-data`,
        codeLine: 467,
        request: {
          params: {
            ...parsedObj,
          },
        },
      });

      this.setScanningError(ReceiptScanningError.PARSED_DATA_FAILED);
      return Promise.reject();
    }
  };

  isThereDataFNS = async (data: APISuccessFNS): Promise<MapingDataFNS> => {
    try {
      if (!isEmpty(data)) {
        return this.mappingDataFromFNS(data);
      }
      throw new Error();
    } catch (e) {
      this.setScanningError(ReceiptScanningError.FNS_HAS_NO_DATA);
      return Promise.reject();
    }
  };

  createReceiptFromScanningQR = (data: MapingDataFNS): IReceipt => ({
    id: uuidv4(),
    isScanned: true,
    inn: data.inn.trim(),
    number: String(data.requestNumber),
    fiscalAccumulator: this.findFiscalAccumulatorID(data.fiscalDriveNumber),
    issuedAt: data.issuedAt,
    totalAmountByFns: data.totalAmountByFns,
    items: data.items.reduce((acc: any[], product: APIFnsItems) => {
      const vat = convertVatToTabObj[product.nds];
      const refund = vatForDisable.has(vat) ? '0' : '1';
      return [
        ...acc,
        {
          article:
            product.productCode && !HandlerObject.is_Object(product.productCode)
              ? product.productCode
              : null,
          name: product.name,
          amount: String(product.quantity),
          measure: this.mappingUnitsFNS(product.unit),
          vat,
          ndsSum: product.ndsSum ? String(product.ndsSum) : null,
          pricePerItem: String(formatPennyToRub(product.price)),
          refund,
          id: uniqueId('item_'),
          paymentAgentType: product.paymentAgentType,
        },
      ];
    }, []),
  });

  handleQRCodeValue = (type: QRCodeType, value: string): Promise<void> | null | undefined => {
    this.qrCodeState.scannedAt = formatDateToValue(moment());
    this.qrCodeState.type = type;
    this.qrCodeState.result = value;
    if (this.receiptOpened && type !== QRCodeType.BASE64) {
      this.setScanningError(ReceiptScanningError.UNKNOWN_ERROR);
    } else if (type === QRCodeType.FNS) {
      return this.handleFnsQRCode(value);
    }
    return undefined;
  };

  handleFnsQRCode = async (values: string): Promise<void> => {
    const normalizedData = parseQrFromDifferentScannerModelToNormalFormat(values);
    // проверка на дату чека
    if (normalizedData && this.more3MonthIssuedAt(normalizedData.date)) {
      this.setScanningError(ReceiptScanningError.DATE_MORE_3MONTHS);
      this.openModal();
    } else {
      const data = await this.getDataFNS(normalizedData);
      if (!data.totalSumOfVatItems) {
        // блокируем чек если нет налогооблагаемых товаров
        this.setScanningError(ReceiptScanningError.NO_TAXABLE_GOODS);
        this.openModal();
      } else if (data) {
        if (this.checkError(data)) {
          this.openModal();
        } else {
          this.receiptOpened = true;
          this.scanningStatus = ReceiptScanningState.RECEIPT_OPENED;
          this.deleteNotTouchedFirstReceipt(); // 1 it must be 1!!
          if (this.push) {
            this.push(this.createReceiptFromScanningQR(data));
          }
          this.setCompanyIdFromQR(); // it is not matter it would be 2 or 3;
          this.setDateFromQR(); // it is not matter it would be 3 or 2;
          this.scanningStatus = ReceiptScanningState.PENDING;
          this.closeModal();
        }
      }
    }
  };

  more3MonthIssuedAt(issuedAt: string | null): boolean {
    const backTimePoint = moment().subtract(3, 'months');
    return moment(issuedAt).isBefore(backTimePoint);
  }

  checkError(receipt: MapingDataFNS): boolean {
    const debugErrors = true;
    // eslint-disable-next-line no-console
    const printError = (line: number, text: string) => debugErrors && emptyFunction(line, text);
    const { inn } = this.values.fiscalReceipts[0] || {};
    if (
      this.isValidInn() &&
      !Object.keys(this.isValidInn() as Record<string, unknown>).includes(receipt.inn)
    ) {
      printError(579, 'ИНН в коде не находится в списке доступных текущему пользователю');
      this.setScanningError(ReceiptScanningError.INCORRECT_INN);
      return true;
    }
    if (!this.ifEntityHasChosen(this.values.company, receipt.inn)) {
      printError(583, 'Проверка чека от одного юр.лица');
      this.setScanningError(ReceiptScanningError.INN_DOES_NOT_MATCH);
      return true;
    }
    if (this.findFiscalAccumulatorID(receipt.fiscalDriveNumber) === null) {
      printError(595, 'Фискальный накопитель не зарегистрирован ни на одно юр.лицо');
      this.setScanningError(ReceiptScanningError.THERE_IS_NO_FISCAL);
      return true;
    }
    if (inn && inn !== receipt.inn) {
      printError(591, 'ИНН в коде не сопадает с ранее сканированными ИНН');
      this.setScanningError(ReceiptScanningError.INN_DOES_NOT_MATCH);
      return true;
    }

    if (receipt.operationType && receipt.operationType !== 1) {
      printError(595, 'Неверный тип операции');
      this.setScanningError(ReceiptScanningError.INCORRECT_TYPE_OPERATION);
      return true;
    }

    if (this.more3MonthIssuedAt(receipt.issuedAt)) {
      printError(599, 'Дата отсканированного чека привышает 3 месяца');
      this.setScanningError(ReceiptScanningError.DATE_MORE_3MONTHS);
      return true;
    }

    if (this.toPreventFutureDate(receipt.issuedAt)) {
      printError(603, 'Дата отсканированного чека в будущем');
      this.setScanningError(ReceiptScanningError.DATE_OF_FUTURE);
      return true;
    }
    if (!this.takeDateFirstReceipt(receipt.issuedAt)) {
      printError(607, 'Дата отсканированного чека не совпадает с датой первого чека');
      this.setScanningError(ReceiptScanningError.DATE_ONE_DATA);
      return true;
    }
    if (this.isThereDoubleReceipt(receipt)) {
      printError(611, 'Такой QR-код уже сканировали (определяем по значению)');
      this.setScanningError(ReceiptScanningError.EXISTING_QR_CODE);
      return true;
    }
    return false;
  }

  handleQRCodeSearching = (type: QRCodeType, value: string): void | Promise<void> | null => {
    if (type === QRCodeType.FNS) {
      return this.QRCodeSearching(value);
    }
    return null;
  };
}
