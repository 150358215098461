import { FC, ReactNode, useCallback, useEffect, useMemo, useRef, HTMLAttributes } from 'react';
import ReactModal from 'react-modal';
import ButtonIcon from '@common/ButtonIcon';
import classNames from 'classnames';
import { SpinnerWidget } from '@sbbol/web-library/desktop/components/SpinnerWidget/SpinnerWidget';
import useResizeObserver from '@react-hook/resize-observer';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import { ReactComponent as CloseIcon } from '@icons/modal-close-icon.svg';
import styles from './styles.module.scss';

interface Props extends HTMLAttributes<HTMLFormElement> {
  header?: ReactNode;
  buttons?: JSX.Element | null;
  onClose: () => void;
  isOpen: boolean;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  maxWidth?: number;
  isLoading?: boolean;
  shouldCloseOnOverlayClick?: boolean;
  colorHeader?: boolean;
  colorFooter?: boolean;
  contentAsForm?: boolean;
  noPadding?: boolean;
  noClosingCross?: boolean;
}

const Modal: FC<Props> = (props) => {
  const {
    header,
    buttons,
    onClose,
    isOpen,
    children,
    size = 'sm',
    maxWidth,
    isLoading,
    shouldCloseOnOverlayClick = true,
    colorHeader = true,
    colorFooter = true,
    contentAsForm,
    noPadding,
    noClosingCross = false,
    ...formProps
  } = props;

  const bodyRef = useRef<HTMLDivElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const style = useMemo(() => {
    return {
      content: {
        maxWidth,
      },
    };
  }, [maxWidth]);

  const handleClose = useCallback(() => {
    if (!isLoading) {
      onClose();
    }
  }, [onClose, isLoading]);

  const setClosePosition = useCallback((node?: HTMLDivElement) => {
    if (noClosingCross) return;
    const content = node || contentRef.current;
    if (content && buttonRef.current) {
      const rect = content.getBoundingClientRect();
      if (rect && buttonRef.current) {
        buttonRef.current.style.top = `${rect.top}px`;
        buttonRef.current.style.left = `${rect.left + rect.width + 4}px`;
      }
    }
  }, []);

  useResizeObserver(contentRef.current, (entry) => {
    setClosePosition();
  });

  const setContentRef = useCallback(
    (node: HTMLDivElement) => {
      contentRef.current = node;
      setClosePosition(node);
    },
    [setClosePosition],
  );

  useEffect(() => {
    const callBack = () => {
      setClosePosition();
    };
    ReactModal.setAppElement('#root');
    window.addEventListener('resize', callBack);
    return () => {
      window.removeEventListener('resize', callBack);
    };
  }, [setClosePosition]);

  useEffect(() => {
    setTimeout(setClosePosition);
  }, [setClosePosition]);

  useEffect(() => {
    setTimeout(() => {
      if (isOpen && bodyRef.current) {
        // чтобы body не скролился вместе с модалкой
        disableBodyScroll(bodyRef.current);
      }
    });
    return () => {
      clearAllBodyScrollLocks();
    };
  }, [isOpen]);

  const content = (
    <>
      {header && (
        <div
          className={classNames(styles.header, {
            [styles.headerColor]: colorHeader,
            [styles.noPadding]: noPadding,
          })}>
          {header}
        </div>
      )}
      <div
        ref={bodyRef}
        className={classNames(styles.body, {
          [styles.bodyMinHeight]: colorHeader && colorFooter,
          [styles.noPadding]: noPadding,
        })}>
        {children}
      </div>
      {buttons && (
        <div
          className={classNames(styles.footer, {
            [styles.footerColor]: colorFooter,
            [styles.noPadding]: noPadding,
          })}>
          {buttons}
        </div>
      )}
    </>
  );

  const contentElement = useCallback(
    (props: any, children: ReactNode) => {
      return (
        <form {...props} {...formProps}>
          {children}
        </form>
      );
    },
    [formProps],
  );

  const reactModalProps: ReactModal.Props = {
    ariaHideApp: false,
    contentRef: setContentRef,
    onRequestClose: handleClose,
    overlayClassName: styles.overlay,
    className: classNames(styles.content, {
      [styles.contentSM]: size === 'sm',
      [styles.contentMD]: size === 'md',
      [styles.contentLG]: size === 'lg',
      [styles.contentXL]: size === 'xl',
    }),
    style,
    isOpen,
    shouldCloseOnOverlayClick,
  };

  if (contentAsForm) {
    reactModalProps.contentElement = contentElement;
  }

  return (
    <ReactModal {...reactModalProps}>
      {content}
      {!noClosingCross && (
        <ButtonIcon
          onClick={handleClose}
          disabled={isLoading}
          ref={buttonRef}
          className={styles.closeButton}>
          <CloseIcon className={styles.close} />
        </ButtonIcon>
      )}
      {isLoading && <SpinnerWidget />}
    </ReactModal>
  );
};

export default Modal;
