import { makeAutoObservable } from 'mobx';
import { VoucherFiscalReceipt, VoucherFiscalReceiptItem } from '@services/api/types/vouchers';
import { calculateVatFraction, formatNumber, roundPenny } from '@services/utils/numbers';
import { HttpClient } from '@services/api/http-client';
import { formatDateToValue } from '@services/utils/dates';
import {
  mapVoucherCreditCardFormToPaymentInfo,
  mapVoucherFormVoucherIssuesToParams,
} from '@services/utils/voucher';
import type { RootStore } from './index';
import {
  VoucherInterface,
  Totals,
  VoucherCardForm,
  VoucherCustomsMark,
  FeeRates,
  FeeRateStings,
} from './entities/voucher';

type CheckedVoucherFiscalItems = Record<
  string,
  { checked: boolean; data: VoucherFiscalReceiptItem; quantity: string | number; receiptId: string }
>;

export const initialTotals: Totals = {
  totalAmount: 0,
  taxationAmount: 0,
  refundableAmount: 0,
};

interface ChangeVoucherOptions {
  setSubmitting?: (value: boolean) => void;
  paymentInfo?: VoucherCardForm | null;
  isSumNotEnough?: boolean;
  customsMark?: Pick<VoucherCardForm, 'customsCode' | 'stampNumber' | 'markedAt'> & {
    id: VoucherCustomsMark['id'];
  };
  extraData?: Partial<Pick<VoucherInterface, 'isReceivedByOperator' | 'isVerifiedByOperator'>>;
}

export default class UiStore {
  modalProcessing = false; // запрос на возврат изменение айтемов

  checkedVoucherFiscalItems: CheckedVoucherFiscalItems = {};

  data: VoucherInterface | null = null;

  feeRates: FeeRates = {};

  identifier = '';

  loading = false;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  setIdentifier = (identifier: string) => {
    this.identifier = identifier;
  };

  setFiscalItemsQuantities = (item: VoucherFiscalReceiptItem, value: number) => {
    if (value <= 0) {
      this.checkedVoucherFiscalItems[item.id] = {
        ...this.checkedVoucherFiscalItems[item.id],
        quantity: item.quantityMax,
        checked: false,
      };
    } else {
      this.checkedVoucherFiscalItems[item.id] = {
        ...this.checkedVoucherFiscalItems[item.id],
        quantity: value,
      };
    }
  };

  getData = async (): Promise<void> => {
    if (!this.identifier) {
      return;
    }
    this.loading = true;
    try {
      const voucher = await HttpClient.getAxios<VoucherInterface>(`/vouchers/${this.identifier}`);
      this.setData(voucher.data);
    } catch (e) {
      HttpClient.log(e, {
        component: 'voucher-edit-store',
        functionName: 'getData',
        codeLine: 89,
        requestUrl: `/vouchers/${this.identifier}`,
        request: 'GET',
      });

      this.rootStore.notification.handleResponseError(e);
    } finally {
      this.loading = false;
    }
  };

  setFeeRates = ({ refundPercentFor10Vat, refundPercentFor20Vat }: FeeRateStings): void => {
    this.feeRates = {
      10: Number(refundPercentFor10Vat),
      20: Number(refundPercentFor20Vat),
    };
  };

  setData = (data: VoucherInterface) => {
    this.data = data;
    const { refundPercentFor10Vat, refundPercentFor20Vat } = this.data;
    this.setFeeRates({ refundPercentFor10Vat, refundPercentFor20Vat });
    this.checkedVoucherFiscalItems = data.fiscalReceipts.reduce(
      (acc: CheckedVoucherFiscalItems, cur) => {
        cur.items.forEach((item) => {
          acc[item.id] = {
            receiptId: cur.id,
            checked: item.isRefundable,
            quantity: item.quantity,
            data: item,
          };
        });
        return acc;
      },
      {},
    );
  };

  closeModal = () => {
    this.data = null;
    this.identifier = '';
    this.loading = false;
    this.checkedVoucherFiscalItems = {};
  };

  handleItemCheck = (item: VoucherFiscalReceiptItem, newValue: boolean) => {
    this.checkedVoucherFiscalItems[item.id] = {
      ...this.checkedVoucherFiscalItems[item.id],
      checked: newValue,
      quantity: item.quantityMax,
    };
  };

  handleVoucherChange = async (options: ChangeVoucherOptions = {}) => {
    const { setSubmitting, paymentInfo, isSumNotEnough, extraData = {}, customsMark } = options;
    setSubmitting?.(true);
    let fiscalReceipts: any[] = [];
    if (this.data) {
      // делается массив только с айдишниками
      fiscalReceipts = this.data.fiscalReceipts.map((fiscal) => {
        return {
          id: fiscal.id,
          items: fiscal.items.map((item) => {
            return {
              id: item.id,
            };
          }),
        };
      });
    }
    Object.values(this.checkedVoucherFiscalItems).forEach((item) => {
      // Это нужно чтобы в fiscalReceipts записать измененнные товары
      if (
        item.checked !== item.data.isRefundable ||
        formatNumber(item.quantity) !== formatNumber(item.data.quantity)
      ) {
        const newItem = {
          id: item.data.id,
          isRefundable: item.checked,
          quantity: String(item.quantity),
          totalPrice: String(Number(item.quantity) * Number(item.data.pricePerItem)),
        };
        let foundReceiptIndex: number | null = null;
        fiscalReceipts.forEach((fiscal, index) => {
          if (foundReceiptIndex !== null) {
            return;
          }
          if (fiscal.id === item.receiptId) {
            foundReceiptIndex = index;
          }
        });
        if (foundReceiptIndex === null) {
          const found = this.data?.fiscalReceipts?.find((fiscal) => fiscal.id === item.receiptId);
          let params: any = {
            id: item.receiptId,
            items: [newItem],
          };
          if (found) {
            const { totalAmount, taxationAmount, refundableAmount } = this.getReceiptTotals(found);
            params = {
              ...params,
              totalAmount: String(totalAmount),
              taxationAmount: String(taxationAmount),
              refundableAmount: String(refundableAmount),
            };
          }
          fiscalReceipts.push(params);
        } else {
          let foundItemIndex: number | null = null;
          fiscalReceipts[foundReceiptIndex].items.forEach((itemInner: any, index: number) => {
            if (foundItemIndex !== null) {
              return;
            }
            if (itemInner.id === item.data.id) {
              foundItemIndex = index;
            }
          });
          if (foundItemIndex !== null) {
            fiscalReceipts[foundReceiptIndex].items[foundItemIndex] = newItem;
          }
        }
      }
    });
    this.modalProcessing = true;
    const voucherParams: any = {
      ...extraData,
      refundableAmount: String(this.voucherModalTotals.refundableAmount),
      taxationAmount: String(this.voucherModalTotals.taxationAmount),
      totalAmount: String(this.voucherModalTotals.totalAmount),
    };
    if (isSumNotEnough) {
      voucherParams.status = 'cancelled_by_refund_office';
    }
    if (paymentInfo) {
      voucherParams.voucherIssues = mapVoucherFormVoucherIssuesToParams(
        this.data?.voucherIssues || {},
        paymentInfo.voucherIssues,
      );
      voucherParams.paymentInfo = {
        id: this.data?.paymentInfo?.id,
        ...mapVoucherCreditCardFormToPaymentInfo(paymentInfo),
      };
    }
    if (customsMark) {
      voucherParams.customsMark = {
        customsCode: customsMark.customsCode,
        stampNumber: customsMark.stampNumber,
        id: customsMark.id,
        markedAt: formatDateToValue(customsMark.markedAt),
        isMarkElectronic: false,
      };
    }
    if (fiscalReceipts.length) {
      voucherParams.fiscalReceipts = fiscalReceipts;
    }
    try {
      const voucher = await HttpClient.patchAxios<VoucherInterface>(
        `/vouchers/${this.data?.identifier}`,
        voucherParams,
      );
      this.closeModal();
      return Promise.resolve(voucher.data);
    } catch (e) {
      HttpClient.log(e, {
        component: 'voucher-edit-store',
        functionName: 'handleVoucherChange',
        codeLine: 247,
        requestUrl: `/vouchers/${this.data?.identifier}`,
        method: 'PATCH',
        request: voucherParams,
      });
      this.rootStore.notification.handleResponseError(e);
      return Promise.reject(e);
    } finally {
      this.modalProcessing = false;
    }
  };

  getReceiptTotals = (data: VoucherFiscalReceipt): Totals =>
    data.items.reduce(
      (acc: Totals, { vat, pricePerItem, id, isFtsRefundable }) => {
        if (isFtsRefundable && this.checkedVoucherFiscalItems[id]?.checked) {
          const totalPrice =
            Number(pricePerItem) * Number(this.checkedVoucherFiscalItems[id].quantity);
          const calc = calculateVatFraction(totalPrice, vat);
          const totalAmount = roundPenny(totalPrice);
          const taxationAmount = roundPenny(calc?.nds);
          const refundableAmount = roundPenny(calc?.refundSum);
          acc.totalAmount += totalAmount === null ? 0 : totalAmount;
          acc.taxationAmount += taxationAmount === null ? 0 : taxationAmount;
          acc.refundableAmount += refundableAmount === null ? 0 : refundableAmount;
        }
        return acc;
      },
      { ...initialTotals },
    );

  get isVoucherChanged(): boolean {
    return Object.values(this.checkedVoucherFiscalItems).some((item) => {
      return (
        item.checked !== item.data.isRefundable ||
        formatNumber(item.quantity) !== formatNumber(item.data.quantity)
      );
    });
  }

  get voucherModalTotals(): Totals {
    if (this.data) {
      return this.data.fiscalReceipts.reduce(
        (acc: Totals, cur) => {
          const receiptTotal = this.getReceiptTotals(cur);
          acc.totalAmount += receiptTotal.totalAmount;
          acc.taxationAmount += receiptTotal.taxationAmount;
          acc.refundableAmount += receiptTotal.refundableAmount;
          return acc;
        },
        { ...initialTotals },
      );
    }
    return { ...initialTotals };
  }

  get voucherModalShow() {
    return !!this.identifier || !!this.data;
  }
}
