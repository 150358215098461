export function debounce(func: any, wait = 150, immediate = true) {
  let timeout: any;
  return function () {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export function isRole(role: string, currentUser: string[] | null): null | boolean {
  return currentUser && currentUser.indexOf(role) >= 0;
}

class HandlerObject {
  is_Object(obj: any) {
    return Object.prototype.toString.call(obj) === '[object Object]';
  }

  isEmpty(obj: any) {
    return (
      obj === null ||
      obj === undefined ||
      (obj.hasOwnProperty('length') && obj.length === 0) ||
      (obj.constructor === Object && Object.keys(obj).length === 0)
    );
  }

  removeUnValidProperty = (obj: { [key: string]: any }): any => {
    const newObj: { [key: string]: any } = {};

    for (const key in obj) {
      if (obj[key] === '' || obj[key] === undefined || obj[key] === null) {
        delete obj[key];
      } else if (Array.isArray(obj[key])) {
        if (!obj[key].length) {
          delete obj[key];
        } else if (obj[key][0] === '' || obj[key][0] === undefined || this.isEmpty(obj[key][0])) {
          delete obj[key];
        } else {
          newObj[key] = obj[key];
        }
      } else if (this.is_Object(obj[key])) {
        if (this.isEmpty(obj[key])) {
          delete obj[key];
        } else {
          newObj[key] = obj[key];
          this.removeUnValidProperty(obj[key]);
        }
      } else {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  };
}

export default new HandlerObject();
