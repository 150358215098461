import { Notification } from '@sbbol/web-library/desktop/components/Notification/Notification';
import { NotificationerrorIcon20 } from '@sbbol/icons/NotificationerrorIcon20';
import { NotificationokIcon20 } from '@sbbol/icons/NotificationokIcon20';
import { NotificationalertIcon20 } from '@sbbol/icons/NotificationalertIcon20';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import Fade from '@common/Fade';
import rootStore from '@store/index';
import { NOTIFICATION_TYPE } from '@store/notification-store';
import { APP_BAR_HEIGHT } from '@consts/app';
import style from './style.module.scss';

const AppNotification = observer(() => {
  const takeIcon = () => {
    switch (rootStore.notification.type) {
      case NOTIFICATION_TYPE.error:
        return <NotificationerrorIcon20 />;
      case NOTIFICATION_TYPE.success:
        return <NotificationokIcon20 />;
      case NOTIFICATION_TYPE.warning:
        return <NotificationalertIcon20 />;
      default:
        return <></>;
    }
  };

  const handleClose = useCallback(() => {
    rootStore.notification.close();
  }, []);

  return (
    <Fade timeout={300} in={rootStore.notification.isVisible}>
      <div className={style.notification} id="notification" style={{ top: APP_BAR_HEIGHT }}>
        <Notification
          onMouseEnter={rootStore.notification.clearTimer}
          onMouseLeave={rootStore.notification.closeByTimeout}>
          <Notification.Icon>{takeIcon()}</Notification.Icon>
          <Notification.Body>
            <Notification.Body.Content>
              <div className={style.wrap}>
                <p className={style.title}>{rootStore.notification.title}</p>
                {rootStore.notification.content.map((item, key) => {
                  return (
                    <p key={key} className={style.value}>
                      {item}
                    </p>
                  );
                })}
              </div>
            </Notification.Body.Content>
          </Notification.Body>
          <Notification.Close onClick={handleClose} />
        </Notification>
      </div>
    </Fade>
  );
});

export default AppNotification;
