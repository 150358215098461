import { Option, OptionExtended, ValueNumber } from '../../store/entities/common';
import { formatNotNumberToNumber } from './numbers';

interface Settings {
  valueKey: string;
  labelKey?: string;
  getOptionLabel?: (option: any) => string;
  combineOptions?: boolean;
}

type GetOptionFromOptionsSettings = Settings;

export const getOptionFromOptions = (
  value: any,
  options: any[] | undefined,
  settings: GetOptionFromOptionsSettings,
): Option => {
  const { valueKey, labelKey, getOptionLabel } = settings;
  const found = options?.find((option) => option[valueKey] === value);
  return {
    value: found?.[valueKey] || '',
    label: getLabel(found, { labelKey, getOptionLabel }),
  };
};

type getOptionsFromAnySettings = Settings;

export const getOptionsFromAny = (
  options: any[] | null | undefined,
  settings: getOptionsFromAnySettings,
): OptionExtended<{ combined?: Option[] }>[] => {
  const { valueKey, labelKey, getOptionLabel, combineOptions } = settings;
  if (!options) {
    return [];
  }
  const res = options.map((item) => ({
    value: item[valueKey] || '',
    label: getLabel(item, { labelKey, getOptionLabel }),
  }));

  if (combineOptions) {
    return res.reduce((acc: OptionExtended<{ combined?: Option[] }>[], cur) => {
      const found = acc.find((item) => {
        return item.label === cur.label;
      });
      if (!found) {
        acc.push({ ...cur, combined: [cur] });
      } else {
        found.combined?.push(cur);
      }
      return acc;
    }, []);
  }
  return res;
};

export const getLabel = (option: any, settings: Omit<Settings, 'valueKey'>): string => {
  const { labelKey, getOptionLabel } = settings;
  if (!option) {
    return '';
  }
  if (getOptionLabel) {
    return getOptionLabel(option) || '';
  }
  if (labelKey) {
    return option[labelKey] || '';
  }
  return '';
};

interface FullNameObject {
  firstName?: string | null;
  lastName?: string | null;
  fatherName?: string | null;
}

export const getFullName = (obj?: FullNameObject): string => {
  if (!obj) {
    return '';
  }
  const formatItem = (item?: string | null) => {
    return item || '';
  };
  return [obj.lastName, obj.firstName, obj.fatherName].reduce((acc: string, cur) => {
    const formattedItem = formatItem(cur);
    if (!formattedItem) {
      return acc;
    }
    if (!acc) {
      return formattedItem;
    }
    return `${acc} ${formattedItem}`;
  }, '');
};

export const getFullFiscalStr = (array: any[]): string => {
  if (!array) {
    return '';
  }
  return array.reduce((acc, fiscal) => {
    return `${acc} ${fiscal.number}`;
  }, '');
};

export const getStringWithFilteredSpace = (str?: string): string => {
  if (!str) {
    return '';
  }
  return str.trim().replace(/\s+/g, ' ');
};

export const getNumberDeclensions = (
  value: ValueNumber,
  forms?: string[],
  defaultValue = '',
): string => {
  let numberValue = formatNotNumberToNumber(value);
  if (numberValue === null) {
    return '';
  }
  numberValue = Math.abs(numberValue) % 100;
  const n1 = numberValue % 10;
  if (numberValue > 10 && numberValue < 20) {
    return forms?.[2] || defaultValue || '';
  }
  if (n1 > 1 && n1 < 5) {
    return forms?.[1] || defaultValue || '';
  }
  if (n1 === 1) {
    return forms?.[0] || defaultValue || '';
  }
  return forms?.[2] || defaultValue || '';
};

export const hasSelection = (): boolean => {
  return !!window.getSelection()?.toString();
};

export const convertObjectToFormData = (obj: any): FormData => {
  const formData = new FormData();
  if (!obj) {
    return formData;
  }
  Object.keys(obj).forEach((key) => {
    formData.append(key, obj[key]);
  });
  return formData;
};

export const isInn = (value: string) => {
  return /^\d+$/.test(value) && value.length < 13;
};
