import { ButtonVariant } from '@store/entities/ui';
import { INewVoucherForm, IReceipt, IReceiptItem } from './types';

export const initialValueReceiptItem: IReceiptItem = {
  article: '',
  name: '',
  amount: 1,
  measure: 1,
  vat: 1,
  pricePerItem: null,
  refund: '1',
  paymentAgentType: null,
};

export const initialValueReceipt: IReceipt = {
  id: 'initId',
  number: '',
  fiscalAccumulator: null,
  issuedAt: '',
  items: [initialValueReceiptItem],
};

export const initialValues: INewVoucherForm = {
  lastName: '',
  firstName: '',
  fatherName: '',
  countryCode: '',
  passportNumber: '',
  issuedAt: '',
  company: '',
  phoneNumber: '',
  fiscalReceipts: [initialValueReceipt],
};

export const voucherGridProps = {
  titleSm: 4,
  controlSm: 8,
  titleMd: 4,
  controlMd: 8,
};

export const deleteReceiptConfirmOptions = {
  title: 'Удалить кассовый чек?',
  confirmButtonText: 'Удалить',
  cancelButtonText: 'Закрыть',
  confirmButtonType: 'danger' as ButtonVariant,
  colorModal: false,
};

interface NumericKeysAndValues {
  [key: number]: number;
}

export const convertVatToTabObj: NumericKeysAndValues = {
  6: 5,
  5: 5,
  4: 2,
  3: 1,
  2: 2,
  1: 1,
};
