import { FC } from 'react';
import { InputFormField } from '@common/Input';
import { InputPasswordFormField } from '@common/InputPassword';
import Button from '@common/Button';
import { useFormikContext } from 'formik';
import styles from './styles.module.scss';

const LoginForm: FC = () => {
  const { isSubmitting } = useFormikContext();
  return (
    <>
      <div className={styles.inputs}>
        <InputFormField name="login" placeholder="Логин" className={styles.input} />
        <InputPasswordFormField name="password" placeholder="Пароль" className={styles.input} />
      </div>
      <Button
        fullWidth
        size="lg"
        variant="general"
        type="submit"
        name="login"
        disabled={isSubmitting}
        isLoading={isSubmitting}>
        Войти
      </Button>
    </>
  );
};

export default LoginForm;
