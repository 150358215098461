/* eslint-disable import/no-cycle */
import { DateRangePickerValue } from '@common/DateRangePicker';
import { IssuersAPI } from '../../components/issuers/types';
import { ICompany } from './generic';

export enum CompanyGroupType {
  REFUND_AGENT = 'refund_agent',
  VOUCHER_AGENT = 'voucher_agent',
  RETAIL = 'retail',
}

export interface ICompanyGroup {
  createdAt: string;
  id: string;
  name: string;
  status: string;
  type: CompanyGroupType;
  updatedAt: string;
  issuers: IssuersAPI[];
  companies: ICompany[];
}

export interface CompanyGroupForm {
  name: string;
  status: string;
  type: string;
  issuers: IssuersAPI[];
}

export interface CompanyGroupFilter {
  name: string;
  status: string | null;
  type: string | null;
  createdAt: DateRangePickerValue | null;
  id: string;
}
