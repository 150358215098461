import { DetailedHTMLProps, ButtonHTMLAttributes, forwardRef } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

const ButtonIcon = forwardRef<
  HTMLButtonElement,
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
>((props, ref) => {
  const { className, children, type = 'button', ...rest } = props;
  return (
    <button {...rest} ref={ref} className={classNames(styles.button, className)} type={type}>
      {children}
    </button>
  );
});

export default ButtonIcon;
