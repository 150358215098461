import { FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import store from '@store/index';
import { observer } from 'mobx-react-lite';
import { HOME_PATH } from '@consts/app';

const LoginRoute: FC<RouteProps> = ({ children }) =>
  store.currentUser.hasToken ? <Redirect to={HOME_PATH} /> : <Route>{children}</Route>;

export default observer(LoginRoute);
