import { FC, useCallback, ChangeEvent, useState, memo } from 'react';
import {
  Input as SbBolInput,
  IInputProps,
} from '@sbbol/web-library/desktop/components/Input/Input';
import classNames from 'classnames';
import ButtonIcon from '@common/ButtonIcon';
import { ReactComponent as EyeOpenIcon } from '@icons/eye_open.svg';
import { ReactComponent as EyeCloseIcon } from '@icons/eye_closed.svg';
import { isEmpty } from '@services/utils/validation';
import styles from './styles.module.scss';

export interface Props extends Omit<IInputProps, 'onChange' | 'className'> {
  onChange: (value: string, params: any) => void;
  className?: string;
  onBlur?: () => void;
}

const InputPassword: FC<Props> = (props) => {
  const { onChange, name, onBlur, className, value, ...rest } = props;
  const [isVisible, setVisibility] = useState(false);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value, { name });
    },
    [onChange, name],
  );

  const setInputRef = useCallback(
    (ref: HTMLInputElement) => {
      if (ref) {
        ref.setAttribute('type', isVisible ? 'text' : 'password');
      }
    },
    [isVisible],
  );

  const handleBlur = useCallback(() => {
    onBlur?.();
  }, [onBlur]);

  return (
    <div className={classNames(styles.password, className)}>
      <SbBolInput
        {...rest}
        value={value}
        onChange={handleChange}
        setInputRef={setInputRef}
        onBlur={handleBlur}
        name={name}
      />
      {!isEmpty(value) && String(value).length > 0 && (
        <ButtonIcon
          tabIndex={-1}
          className={styles.eye}
          onClick={() => {
            setVisibility(!isVisible);
          }}>
          {isVisible ? <EyeOpenIcon /> : <EyeCloseIcon />}
        </ButtonIcon>
      )}
    </div>
  );
};

export default memo(InputPassword);
