import { AxiosResponse } from 'axios';

const arrayKeyRegExp = /\[\d\]/;

export function openFromResponse(response: AxiosResponse) {
  const { data, headers } = response;
  const a = document.createElement('a');
  const downloadUrl = URL.createObjectURL(data);
  a.style.display = 'none';
  a.setAttribute('href', downloadUrl);
  a.setAttribute('target', '_blank');
  const filename = headers['x-filename'];
  if (filename) {
    a.setAttribute('download', decodeURIComponent(filename));
  }
  a.click();
  URL.revokeObjectURL(downloadUrl);
}

export const expandArrayKeys = function f(data: { [key in string]: any } | string | number) {
  if (typeof data === 'number' || typeof data === 'string' || typeof data === 'undefined') {
    return data;
  }
  const res: any = {};
  Object.keys(data).forEach((key) => {
    const isKeyArray = arrayKeyRegExp.test(key);
    if (isKeyArray) {
      const index = arrayKeyRegExp.exec(key)?.[0].replace(/[\[\]]/g, '');
      const cleanKey = key.replace(arrayKeyRegExp, '');
      if (index) {
        if (!res[cleanKey]) {
          res[cleanKey] = new Array(Number(index)).fill({});
        }
        res[cleanKey][index] = f(data[key]);
      }
    } else {
      res[key] = f(data[key]);
    }
  });
  return res;
};
