import { makeAutoObservable } from 'mobx';
import { AxiosError } from 'axios';
import { ReactNode } from 'react';
import { FormikErrors, FormikHelpers } from 'formik';
import config from '@consts/config';
import type { RootStore } from './index';
import styles from './styles.module.scss';
import { convertViolationsToObject } from '../services/utils/validation';

export const baseErrorMessage = 'Валидация обнаружила ошибку';

export enum NOTIFICATION_TYPE {
  error = 'error',
  success = 'success',
  warning = 'warning',
}

export enum TITLE_COMPANY_GROUP {
  companyGroupAdd = 'Холдинг добавлен.',
  companyGroupEdit = 'Холдинг изменен.',
}

export enum TITLE_COMPANY {
  companyAdd = 'Юридическое лицо добавлено.',
  companyEdit = 'Юридическое лицо изменено.',
}

export enum TITLE_FISCAL {
  fiscalAdd = 'Фискальный накопитель добавлен.',
  fiscalEdit = 'Фискальный накопитель изменен.',
}

export enum TITLE_BRAND {
  brandAdd = 'Бренд добавлен.',
  brandEdit = 'Бренд изменен.',
}

export enum TITLE_SHOP {
  shopAdd = 'Магазин добавлен.',
  shopEdit = 'Магазин изменен.',
}

export enum REFUND_OFFICE {
  refindOfficeAdd = 'Пункт возврата добавлен.',
  refindOfficeEdit = 'Пункт возврата изменен.',
}

export enum CUSTOMS_OFFICE {
  customsOfficeAdd = 'Пункт пропуска добавлен.',
  customsOfficeEdit = 'Пункт пропуска изменен.',
}

export enum Mailbox {
  mailboxAdd = 'Почтовый ящик добавлен.',
  mailboxEdit = 'Почтовый ящик изменен.',
}

export enum TITLE_ERROR {
  error = 'Что то пошло не так!',
}

type UnitNotificationType =
  | NOTIFICATION_TYPE.error
  | NOTIFICATION_TYPE.success
  | NOTIFICATION_TYPE.warning;

interface HandleResponseErrorOptions {
  helpers?: Pick<FormikHelpers<any>, 'setFormikState'>;
  errorsMapper?: (errors: any) => FormikErrors<any>;
  validateMessages?: Array<string | ReactNode>;
  closeByTimeout?: boolean;
}

export default class NotificationStore {
  isVisible = false;

  title: string = TITLE_ERROR.error;

  content: Array<string | ReactNode> = [''];

  type: UnitNotificationType = NOTIFICATION_TYPE.error;

  timeOut = 4000;

  timer: ReturnType<typeof setTimeout> | null = null;

  isCloseByTimeout = true;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  open = (
    content: string,
    type: UnitNotificationType,
    value: Array<string | ReactNode>,
    timeOut = true,
  ): void => {
    this.isVisible = true;
    this.title = content;
    this.type = type;
    this.content = value;
    this.isCloseByTimeout = timeOut;
    if (timeOut) {
      this.closeByTimeout();
    }
  };

  handleSimpleError = (error: AxiosError | string): void => {
    this.open(
      'Произошла ошибка!',
      NOTIFICATION_TYPE.error,
      [typeof error === 'string' ? error : JSON.stringify(error)],
      true,
    );
  };

  handleResponseError = (
    e: AxiosError,
    { helpers, errorsMapper, closeByTimeout = true }: HandleResponseErrorOptions = {},
  ): void => {
    let validateMessages = baseErrorMessage;

    const handleViolationsErrors = () => {
      const violations = e?.response?.data?.violations;

      if (helpers && violations) {
        let errors = convertViolationsToObject(violations);
        if (errorsMapper) {
          errors = errorsMapper(errors);
        }
        Object.values(errors).forEach((value) => {
          validateMessages = String(value);
        });
        helpers.setFormikState((prev) => ({
          ...prev,
          errors,
          submitCount: prev.submitCount + 1,
        }));
        return true;
      }
      return false;
    };

    if (e?.response?.status === 500) {
      const link = e?.response?.headers?.['x-debug-token-link'] || '';
      if (config.stand !== 'prod') {
        this.open(
          TITLE_ERROR.error,
          NOTIFICATION_TYPE.error,
          [
            'Что-то пошло не так. Скопируйте и отправьте в поддержку ссылку для анализа ошибки.',
            <a key="link" href={link} target="_blank" rel="noreferrer" className={styles.break}>
              {link}
            </a>,
          ],
          closeByTimeout,
        );
      } else {
        this.open(TITLE_ERROR.error, NOTIFICATION_TYPE.error, [], closeByTimeout);
      }
    } else if (handleViolationsErrors()) {
      // Не надо тут ничего про ваучер писать, эта ошибка для всех форм
      // Если нужна кастомна валидация - нужно ее писать в catch нужного запроса
      this.open('Ошибки валидации', NOTIFICATION_TYPE.warning, [validateMessages], closeByTimeout);
    } else {
      const error =
        e?.response?.data?.violations?.[0]?.message ||
        e?.response?.data?.detail ||
        e.message ||
        'Ошибка';
      this.open(error, NOTIFICATION_TYPE.error, [validateMessages], closeByTimeout);
    }
  };

  closeByTimeout = (): void => {
    this.clearTimer();
    if (this.isCloseByTimeout) {
      this.timer = setTimeout(() => {
        this.close();
      }, this.timeOut);
    }
  };

  clearTimer = (): void => {
    if (this.isCloseByTimeout && this.timer) {
      clearTimeout(this.timer);
    }
  };

  close = (): void => {
    this.isVisible = false;
  };
}
