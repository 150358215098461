// eslint-disable-next-line import/no-extraneous-dependencies
import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as moment from 'moment';
import 'moment/locale/ru';
import '@sbbol/web-library/styles/desktop/styles.css';
import './assets/styles/globals.scss';
import './assets/styles/layout.scss';
import { setEntrancePoint } from '@services/utils/storage';
import App from './App';
import { initGrid } from './services/utils/grid';

moment.locale('ru');

initGrid();

// Запоминаем точку входа в приложение
setEntrancePoint();

ReactDOM.render(
  // hydrate вызывает warning в консоле, hydrate - для SSR
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);
