/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable class-methods-use-this */
import { makeAutoObservable } from 'mobx';
import { EOrderDirection } from '@sbbol/web-library/desktop/components/Tables/TableBasic/enums';
import mapKeys from 'lodash/mapKeys';
import { Totals, VoucherIssueManual } from '@store/entities/voucher';
import { roundRub, formatRubToPenny, calculateVatFraction } from '@services/utils/numbers';
import { IGroupsAPIUser, IUserAPIUserData } from '@services/api/types/users';
import { EUserRole } from '@store/entities/users';
import { GENERIC, HttpClient } from '@services/api/http-client';
import { IGenericAPICountriesResponse } from '@services/api/types/generic';
import { IReceipt, IReceiptItem } from '@containers/roles/cashier/home/NewVoucherPage/types';
import { IFiscalAPI } from '@components/fiscalAccumulator/types';

import {
  ICompany,
  IDictionary,
  IGenericVat,
  IUnits,
  typesOfSpecialVATRates,
  vatRatesMapType,
  feeRecord,
  ICalculateResult,
  IssuersListAPI,
} from './entities/generic';
import type { RootStore } from './index';
import { OptionExtended, vatExcludeSet } from './entities/common';
import { QUERY_PARAMS } from './entities/table';
import { IBrandCategory } from './entities/brands';
import { CustomsOfficesTypes } from './entities/customs-offices';

type StatusOption = OptionExtended<{ id: any }>;

interface IOptions {
  promoMaterialsDeliveryType: StatusOption[];
  companyStatuses: StatusOption[];
  companies: OptionExtended<ICompany>[];
  groupCompanyType: StatusOption[];
  fiscalAccumulatorStatus: StatusOption[];
  fiscalAccumulatorOptions: OptionExtended[];
  userStatus: StatusOption[];
  roles: OptionExtended<IGroupsAPIUser>[];
  issuers: OptionExtended[];
  units: OptionExtended[];
  issuersStatus: StatusOption[];
  refundStatus: StatusOption[];
  countries: OptionExtended<IGenericAPICountriesResponse>[];
  voucherStatuses: StatusOption[];
  registryStatuses: StatusOption[];
  logisticRegistryStatuses: StatusOption[];
  registryTypes: StatusOption[];
  companyGroupStatuses: StatusOption[];
  brandStatuses: StatusOption[];
  brandCategories: IBrandCategory[];
  shopStatuses: StatusOption[];
  mailboxStatuses: StatusOption[];
  refundOfficeStatuses: StatusOption[];
  customsOfficeStatuses: StatusOption[];
  customsOfficeTypes: StatusOption[];
  vats: OptionExtended<IGenericVat>[];
  companyContractTypes: StatusOption[];
  paymentOrderStatuses: StatusOption[];
  paymentOrderBankStatusesGrey: StatusOption[];
  paymentOrderBankStatusesNextStep: StatusOption[];
  paymentOrderBankStatuses: StatusOption[];
  paymentOrderTypes: StatusOption[];
  voucherIssueTypes: StatusOption[];
  voucherIssueCategories: StatusOption[];
  voucherIssuesManual: VoucherIssueManual[];
}

const dictionaryInitial: IDictionary = {
  promoMaterialsDeliveryType: {},
  companyStatus: {},
  companyGroupType: {},
  fiscalAccumulatorStatus: {},
  userStatus: {},
  issuersStatus: {},
  issuers: {},
  refundStatus: {},
  voucherStatuses: {},
  registryStatuses: {},
  logisticRegistryStatuses: {},
  registryTypes: {},
  companyGroupStatuses: {},
  brandStatuses: {},
  shopStatuses: {},
  mailboxStatuses: {},
  refundOfficeStatuses: {},
  customsOfficeStatuses: {},
  customsOfficeTypes: {},
  companyContractTypes: {},
  paymentOrderStatuses: {},
  paymentOrderBankStatuses: {},
  paymentOrderBankStatusesGrey: {},
  paymentOrderBankStatusesNextStep: {},
  paymentOrderTypes: {},
  voucherIssueTypes: {},
  voucherIssueCategories: {},
};

const optionsInitial: IOptions = {
  promoMaterialsDeliveryType: [],
  companies: [],
  companyStatuses: [],
  groupCompanyType: [],
  fiscalAccumulatorStatus: [],
  fiscalAccumulatorOptions: [],
  userStatus: [],
  roles: [],
  issuers: [],
  units: [],
  issuersStatus: [],
  voucherStatuses: [],
  registryStatuses: [],
  logisticRegistryStatuses: [],
  registryTypes: [],
  companyGroupStatuses: [],
  refundStatus: [],
  countries: [],
  vats: [],
  brandStatuses: [],
  brandCategories: [],
  shopStatuses: [],
  mailboxStatuses: [],
  refundOfficeStatuses: [],
  customsOfficeStatuses: [],
  customsOfficeTypes: [],
  companyContractTypes: [],
  paymentOrderStatuses: [],
  paymentOrderBankStatusesGrey: [],
  paymentOrderBankStatusesNextStep: [],
  paymentOrderBankStatuses: [],
  paymentOrderTypes: [],
  voucherIssueTypes: [],
  voucherIssueCategories: [],
  voucherIssuesManual: [],
};

const optionsLoadingInitial: Record<keyof IOptions, boolean> = {
  promoMaterialsDeliveryType: false,
  companies: false,
  companyStatuses: false,
  groupCompanyType: false,
  fiscalAccumulatorStatus: false,
  fiscalAccumulatorOptions: false,
  userStatus: false,
  roles: false,
  issuers: false,
  units: false,
  issuersStatus: false,
  voucherStatuses: false,
  registryStatuses: false,
  logisticRegistryStatuses: false,
  registryTypes: false,
  countries: false,
  refundStatus: false,
  vats: false,
  companyGroupStatuses: false,
  brandStatuses: false,
  brandCategories: false,
  shopStatuses: false,
  mailboxStatuses: false,
  refundOfficeStatuses: false,
  customsOfficeStatuses: false,
  customsOfficeTypes: false,
  companyContractTypes: false,
  paymentOrderStatuses: false,
  paymentOrderBankStatusesGrey: false,
  paymentOrderBankStatusesNextStep: false,
  paymentOrderBankStatuses: false,
  paymentOrderTypes: false,
  voucherIssueTypes: false,
  voucherIssueCategories: false,
  voucherIssuesManual: false,
};

const { ROLE_CLIENT_CASHIER } = EUserRole;

export default class GenericEntitiesStore {
  dictionary = { ...dictionaryInitial };

  vatsRaitsMap: vatRatesMapType | null = null;

  defaultVatsRaitsMap: vatRatesMapType | null = null;

  options = { ...optionsInitial };

  optionsLoading = { ...optionsLoadingInitial };

  // флаг загрузки
  isLoading = false;

  // колличество пунктов выписки
  issuersNumber = 0;

  // имеется ли роль кассира среди пользователей
  thereIsACashierRole = false;

  // колличество фискальных накопителей
  fiscalAccumulatorsNumber = 0;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  fetchStepperInfo = async (): Promise<void> => {
    try {
      this.isLoading = true;
      const [issuersData, fiscalAccData, usersData] = await Promise.all([
        HttpClient.getAxios<IssuersListAPI[]>(GENERIC.issuers),
        HttpClient.getAxios<IFiscalAPI[]>(GENERIC.fiscalAccumulators),
        HttpClient.getAxios<IUserAPIUserData[]>('/users'),
      ]);
      this.issuersNumber = +issuersData?.data.length;
      this.fiscalAccumulatorsNumber = fiscalAccData.data.length;
      this.thereIsACashierRole = usersData.data
        .map(({ groups }) => groups.map(({ roles }) => roles.map((el) => el)))
        .flat()
        .flat()
        .some((el) => el === ROLE_CLIENT_CASHIER);
    } catch (error) {
      HttpClient.log(error, {
        component: 'GenericEntitiesStore',
        functionName: 'fetchStepperInfo',
        codeLine: 227,
        requestUrl: GENERIC.issuers,
        method: 'GET',
      });
    } finally {
      this.isLoading = false;
    }
  };

  convertToSuggestions(res: Record<string, string>): StatusOption[] {
    return Object.keys(res).reduce((acc: StatusOption[], item, key) => {
      return [...acc, { id: key, label: res[item], value: item }];
    }, []);
  }

  convertToRus(word: any, subject: keyof IDictionary): string {
    return this.dictionary[subject][word] || word;
  }

  getFiscalsOption = (companyId?: string): OptionExtended[] => {
    const result: OptionExtended = [];
    if (this.rootStore.currentUser.currentIssuerPoint?.company) {
      this.rootStore.currentUser.currentIssuerPoint.company.forEach((company) => {
        if (!companyId || company.id === companyId) {
          company.fiscalAccumulators.forEach(({ id, factoryNumber, status, deletedAt }) => {
            if (deletedAt === null && status === 'active') {
              result.push({
                id,
                label: factoryNumber,
                value: id,
              });
            }
          });
        }
      });
    }
    this.options.fiscalAccumulatorOptions = result;
    return result;
  };

  fiscalAccumulatorsIds = (companyId?: string): Set<string> => {
    const result = new Set('');
    if (this.rootStore.currentUser.currentIssuerPoint?.company) {
      this.rootStore.currentUser.currentIssuerPoint.company.forEach((company) => {
        if (company.id === companyId) {
          company.fiscalAccumulators.forEach(({ deletedAt, status, id }) => {
            if (deletedAt === null && status === 'active') {
              result.add(id);
            }
          });
        }
      });
    }
    return result;
  };

  getAllCompanyOption = (): OptionExtended[] => {
    if (this.rootStore.currentUser.currentIssuerPoint?.company) {
      return this.rootStore.currentUser.currentIssuerPoint.company.map((company) => ({
        id: company.id,
        label: company.name,
        value: company.id,
      }));
    }
    return [];
  };

  getCompanyOption = (fiscalValue?: string | boolean): OptionExtended[] => {
    let companies = this.rootStore.currentUser.currentIssuerPoint?.company || []; // нахожу компанию по id или значение фискльника
    companies = companies.filter((company) =>
      company.fiscalAccumulators.find(
        (fiscal) =>
          (fiscal.id === fiscalValue || fiscal.factoryNumber === fiscalValue) &&
          fiscal.deletedAt === null,
      ),
    );
    if (companies?.length) {
      return companies.reduce((acc: any, company) => {
        return [...acc, { id: company.id, label: company.name, value: company.id }];
      }, []);
    }
    return this.getAllCompanyOption();
  };

  isFetchBlock = (optionKey: keyof IOptions): boolean => {
    return !!(this.options[optionKey].length || this.optionsLoading[optionKey]);
  };

  fetchDictionary = async (
    optionKey: keyof IOptions,
    dictionaryKey: keyof IDictionary,
    url: string,
  ) => {
    if (this.isFetchBlock(optionKey)) {
      return;
    }
    this.optionsLoading[optionKey] = true;
    try {
      const { data } = await HttpClient.getAxios<Record<string, string>>(url);
      this.dictionary[dictionaryKey] = data;
      this.options[optionKey] = this.convertToSuggestions(data) as any;
    } catch (e) {
      HttpClient.log(e, {
        component: 'GenericEntitiesStore',
        functionName: 'fetchDictionary',
        codeLine: 275,
        requestUrl: url,
        method: 'GET',
      });
    } finally {
      this.optionsLoading[optionKey] = false;
    }
  };

  fetchCountries = async (): Promise<void> => {
    if (this.isFetchBlock('countries')) {
      return;
    }
    this.optionsLoading.countries = true;
    try {
      const pages = 3;
      const response = await Promise.all(
        new Array(pages).fill(null).map((_, index) => {
          return HttpClient.generic<IGenericAPICountriesResponse[]>(GENERIC.countries, {
            params: {
              [`${QUERY_PARAMS.order}[order]`]: EOrderDirection.ASC,
              taxfeeEnabled: true,
              [QUERY_PARAMS.page]: index + 1,
              [QUERY_PARAMS.limit]: 100,
            },
          });
        }),
      );
      this.options.countries = response
        .reduce((acc, cur) => [...acc, ...cur], [])
        .map((item) => ({
          ...item,
          label: item.name,
          value: item.id,
        }));
    } catch (e) {
      HttpClient.log(e, {
        component: 'GenericEntitiesStore',
        functionName: 'fetchCountries',
        codeLine: 313,
        requestUrl: GENERIC.countries,
        method: 'GET',
      });
    } finally {
      this.optionsLoading.countries = false;
    }
  };

  fetchBrandCategories = async (): Promise<void> => {
    if (this.isFetchBlock('brandCategories')) {
      return;
    }
    this.optionsLoading.brandCategories = true;
    try {
      const { data } = await HttpClient.getAxios<IBrandCategory[]>('/brand_categories');
      this.options.brandCategories = data;
    } catch (e) {
      HttpClient.log(e, {
        component: 'GenericEntitiesStore',
        functionName: 'fetchBrandCategories',
        codeLine: 333,
        requestUrl: '/brand_categories',
        method: 'GET',
      });
    } finally {
      this.optionsLoading.brandCategories = false;
    }
  };

  fetchCustomsOfficeTypes = async (): Promise<void> => {
    if (this.isFetchBlock('customsOfficeTypes')) {
      return;
    }
    this.optionsLoading.customsOfficeTypes = true;
    try {
      const { data } = await HttpClient.getAxios<CustomsOfficesTypes>('/checkpoint/types');
      const dictionary = data.ru;
      this.dictionary.customsOfficeTypes = dictionary;
      this.options.customsOfficeTypes = this.convertToSuggestions(dictionary) as any;
    } catch (e) {
      HttpClient.log(e, {
        component: 'GenericEntitiesStore',
        functionName: 'fetchCustomsOfficeTypes',
        codeLine: 356,
        requestUrl: '/checkpoint/types',
        method: 'GET',
      });
    } finally {
      this.optionsLoading.customsOfficeTypes = false;
    }
    await this.fetchDictionary('customsOfficeTypes', 'customsOfficeTypes', '/checkpoint/types');
  };

  fetchPromoMaterialsDeliveryType = async (): Promise<void> => {
    await this.fetchDictionary(
      'promoMaterialsDeliveryType',
      'promoMaterialsDeliveryType',
      '/promo_materials/delivery_types',
    );
  };

  fetchVoucherStatuses = async (): Promise<void> => {
    await this.fetchDictionary('voucherStatuses', 'voucherStatuses', '/vouchers/statuses');
  };

  fetchRegistryStatuses = async (): Promise<void> => {
    await this.fetchDictionary(
      'registryStatuses',
      'registryStatuses',
      '/voucher_registries/statuses',
    );
  };

  fetchLogisticRegistriesStatuses = async (): Promise<void> => {
    await this.fetchDictionary(
      'logisticRegistryStatuses',
      'logisticRegistryStatuses',
      '/voucher_registries/statuses',
    );
  };

  fetchRegistryTypes = async (): Promise<void> => {
    await this.fetchDictionary('registryTypes', 'registryTypes', '/voucher_registries/types');
  };

  fetchCompanyContractTypes = async (): Promise<void> => {
    await this.fetchDictionary(
      'companyContractTypes',
      'companyContractTypes',
      '/companies/contract_types',
    );
  };

  fetchVoucherIssueTypes = async (): Promise<void> => {
    await this.fetchDictionary('voucherIssueTypes', 'voucherIssueTypes', '/voucher_issues/types');
  };

  fetchVoucherIssueCategories = async (): Promise<void> => {
    await this.fetchDictionary(
      'voucherIssueCategories',
      'voucherIssueCategories',
      '/voucher_payment_info/issue_categories',
    );
  };

  fetchVoucherIssuesManual = async (): Promise<void> => {
    if (this.isFetchBlock('voucherIssuesManual')) {
      return;
    }
    this.optionsLoading.voucherIssuesManual = true;
    try {
      const { data } = await HttpClient.getAxios<VoucherIssueManual[]>(
        '/voucher_payment_info/issue_manual',
      );
      this.options.voucherIssuesManual = data;
    } catch (e) {
      HttpClient.log(e, {
        component: 'GenericEntitiesStore',
        functionName: 'fetchVoucherIssuesManual',
        codeLine: 432,
        requestUrl: '/voucher_payment_info/issue_manual',
        method: 'GET',
      });
    } finally {
      this.optionsLoading.voucherIssuesManual = false;
    }
  };

  async fetchVatsOptions(): Promise<void> {
    if (this.isFetchBlock('vats')) {
      return;
    }
    this.optionsLoading.vats = true;
    try {
      const { data } = await HttpClient.getAxios<IGenericVat[]>('/vat_raits');

      const vatRatesMap = mapKeys(data, 'id');
      this.defaultVatsRaitsMap = vatRatesMap;
      this.vatsRaitsMap = vatRatesMap;
      this.options.vats = data
        .sort((a, b) => {
          return b.vat - a.vat;
        })
        .map((item) => ({
          ...item,
          value: item.id,
          label: `${item.vat}%`,
        }))
        .filter(({ id }) => !vatExcludeSet.has(id));
    } catch (e: any) {
      HttpClient.log(e, {
        component: 'GenericEntitiesStore',
        functionName: 'fetchVatsOptions',
        codeLine: 478,
        requestUrl: '/vat_raits',
        method: 'GET',
      });
      this.rootStore.notification.handleResponseError(e, { closeByTimeout: false });
    } finally {
      this.optionsLoading.vats = false;
    }
  }

  setSpecialVatRates({
    sumOfVatItems,
    refundPercentsFor10Vat,
    refundPercentsFor20Vat,
  }: typesOfSpecialVATRates): void {
    if (!sumOfVatItems || !this.vatsRaitsMap || !this.defaultVatsRaitsMap) return;
    const amountInKopecks = sumOfVatItems * 100;

    // функция выбирает подходящее значение fee из таблицы ставок
    const calcFee = (totalSumm: number, feeTable: feeRecord[]): any => {
      const verifiedEntry = feeTable.find(({ from, to }) =>
        to ? totalSumm >= from && totalSumm < to : totalSumm >= from,
      );
      if (verifiedEntry) {
        return verifiedEntry.fee;
      }
      return null;
    };

    // корректируем ставки НДС для 20%
    if (refundPercentsFor20Vat.length > 0) {
      const caclRes = calcFee(amountInKopecks, refundPercentsFor20Vat);
      if (caclRes) {
        this.vatsRaitsMap[1].fee = caclRes;
      }
    } else {
      // возвращаем обратно значения по умолчанию
      this.vatsRaitsMap[1].fee = this.defaultVatsRaitsMap[1].fee;
    }

    // корректируем ставки НДС для 10%
    if (refundPercentsFor10Vat.length > 0 && this.vatsRaitsMap) {
      const caclRes = calcFee(amountInKopecks, refundPercentsFor10Vat);
      if (caclRes) {
        this.vatsRaitsMap[2].fee = caclRes;
      }
    } else {
      // возвращаем обратно значения по умолчанию
      this.vatsRaitsMap[2].fee = this.defaultVatsRaitsMap[2].fee;
    }
  }

  getAllReceiptsTotals = (receipts: IReceipt[]): Totals => {
    return receipts.reduce(
      (acc: Totals, receipt) => {
        const { totalAmount, taxationAmount, refundableAmount } = this.getReceiptTotalsForm(
          receipt.items,
        );
        acc.totalAmount += totalAmount;
        acc.taxationAmount += taxationAmount;
        acc.refundableAmount += refundableAmount;
        return acc;
      },
      {
        totalAmount: 0,
        taxationAmount: 0,
        refundableAmount: 0,
      },
    );
  };

  getReceiptTotalsForm = (items: IReceiptItem[]): Totals =>
    items.reduce(
      (acc: Totals, { vat, pricePerItem, refund, amount }) => {
        const vatObject = this.calculateVat(vat, pricePerItem);
        if (refund === '1' && vatObject) {
          const amountNumber = Number(amount);
          const totalAmount = roundRub(vatObject.total * amountNumber);
          const taxationAmount = roundRub(vatObject.nds * amountNumber);
          const refundableAmount = roundRub(vatObject.refundSum * amountNumber);
          acc.totalAmount += totalAmount === null ? 0 : totalAmount;
          acc.taxationAmount += taxationAmount === null ? 0 : taxationAmount;
          acc.refundableAmount += refundableAmount === null ? 0 : refundableAmount;
        }
        return acc;
      },
      {
        totalAmount: 0,
        taxationAmount: 0,
        refundableAmount: 0,
      },
    );

  getNdsSum = ({ vat, pricePerItem, refund, amount }: IReceiptItem): string => {
    const vatObject = this.calculateVat(vat, pricePerItem);
    if (refund === '1' && vatObject) {
      const amountNumber = Number(amount);
      const taxationAmount = formatRubToPenny(vatObject.nds * amountNumber);
      return String(taxationAmount);
    }
    return '0';
  };

  calculateVat = (vatId: number, pricePerItem: number | null): ICalculateResult | null => {
    if (!pricePerItem) return null;
    if (!this.vatsRaitsMap) return null;
    const vatRecord: IGenericVat = this.vatsRaitsMap[vatId];
    if (!vatRecord) return null;
    const { refundSum, nds } = calculateVatFraction(pricePerItem, vatRecord);
    return {
      total: pricePerItem,
      refundSum,
      nds,
    };
  };

  fetchCompanyGroupStatus = async (): Promise<void> => {
    await this.fetchDictionary(
      'companyGroupStatuses',
      'companyGroupStatuses',
      GENERIC.company_group_statuses,
    );
  };

  fetchCompanyGroupType = async (): Promise<void> => {
    await this.fetchDictionary('groupCompanyType', 'companyGroupType', GENERIC.company_group_types);
  };

  fetchCompanyStatus = async (): Promise<void> => {
    await this.fetchDictionary('companyStatuses', 'companyStatus', GENERIC.company_statuses);
  };

  fetchFiscalAccumulatorStatus = async (): Promise<void> => {
    await this.fetchDictionary(
      'fiscalAccumulatorStatus',
      'fiscalAccumulatorStatus',
      GENERIC.fiscal_accumulator_statuses,
    );
  };

  fetchIssuersStatus = async (): Promise<void> => {
    await this.fetchDictionary('issuersStatus', 'issuersStatus', GENERIC.issuers_statuses);
  };

  fetchPaymentOrderStatuses = async (): Promise<void> => {
    await this.fetchDictionary(
      'paymentOrderStatuses',
      'paymentOrderStatuses',
      '/payment_orders/statuses',
    );
  };

  fetchPaymentOrderBankStatusesGrey = async (): Promise<void> => {
    await this.fetchDictionary(
      'paymentOrderBankStatusesGrey',
      'paymentOrderBankStatusesGrey',
      '/payment_orders/statuses/grey',
    );
  };

  fetchPaymentOrderBankStatusesNextStep = async (): Promise<void> => {
    await this.fetchDictionary(
      'paymentOrderBankStatusesNextStep',
      'paymentOrderBankStatusesNextStep',
      '/payment_orders/statuses/nextstep',
    );
  };

  fetchPaymentOrderBankStatuses = async (): Promise<void> => {
    await this.fetchDictionary(
      'paymentOrderBankStatuses',
      'paymentOrderBankStatuses',
      '/payment_orders/bank_statuses',
    );
  };

  fetchPaymentOrderTypes = async (): Promise<void> => {
    await this.fetchDictionary('paymentOrderTypes', 'paymentOrderTypes', '/payment_orders/types');
  };

  fetchUsersRoles = async (role?: EUserRole): Promise<undefined> => {
    // доступано только после смены пароля
    // запрашивать список ролей могут не все
    if (
      ![
        EUserRole.ROLE_SBER_TAX_FREE_ADMIN,
        EUserRole.ROLE_REFUND_ADMINISTRATOR,
        EUserRole.ROLE_CLIENT_ADMINISTRATOR,
        EUserRole.ROLE_SBER_TAX_FREE_SUPPORT_OPERATOR,
      ].includes((role || this.rootStore.currentUser.role) as EUserRole) ||
      this.isFetchBlock('roles')
    ) {
      return;
    }
    this.optionsLoading.roles = true;
    try {
      const data = await HttpClient.generic<IGroupsAPIUser[]>(GENERIC.roles);
      this.options.roles = data.map((item) => ({
        ...item,
        label: item.name,
        value: item.id,
      }));
    } catch (e) {
      HttpClient.log(e, {
        component: 'GenericEntitiesStore',
        functionName: 'fetchUsersRoles',
        codeLine: 573,
        requestUrl: GENERIC.roles,
        method: 'GET',
      });

      Promise.reject(e);
    } finally {
      this.optionsLoading.roles = false;
    }
  };

  getGroupIdByRoleAlias = (role: EUserRole): string => {
    const found = this.options.roles.find((item) => item.roles.includes(role));
    return found?.id || '';
  };

  getRoleAliasByGroupId = (role: string): EUserRole | null => {
    const found = this.options.roles.find((item) => item.id === role);
    return found?.roles?.[0] || null;
  };

  fetchUsersStatus = async (): Promise<void> => {
    await this.fetchDictionary('userStatus', 'userStatus', GENERIC.user_infos);
  };

  fetchRefundStatus = async (): Promise<void> => {
    await this.fetchDictionary('refundStatus', 'refundStatus', GENERIC.refund_statuses);
  };

  fetchBrandStatuses = async (): Promise<void> => {
    await this.fetchDictionary('brandStatuses', 'brandStatuses', GENERIC.brands_statuses);
  };

  fetchShopStatuses = async (): Promise<void> => {
    await this.fetchDictionary('shopStatuses', 'shopStatuses', GENERIC.shops_statuses);
  };

  fetchRefundOfficeStatuses = async (): Promise<void> => {
    await this.fetchDictionary(
      'refundOfficeStatuses',
      'refundOfficeStatuses',
      GENERIC.refund_office_statuses,
    );
  };

  fetchCustomsOfficeStatuses = async (): Promise<void> => {
    await this.fetchDictionary(
      'customsOfficeStatuses',
      'customsOfficeStatuses',
      GENERIC.customs_office_statuses,
    );
  };

  fetchMailboxStatuses = async (): Promise<void> => {
    await this.fetchDictionary('mailboxStatuses', 'mailboxStatuses', GENERIC.mailbox_statuses);
  };

  fetchUnits = async (): Promise<undefined> => {
    if (this.isFetchBlock('units')) {
      return;
    }
    this.optionsLoading.roles = true;
    try {
      const { data } = await HttpClient.getAxios<IUnits[]>(GENERIC.units);
      this.options.units = data
        .map((item) => {
          return {
            ...item,
            id: item.id,
            label: item.voucherName,
            value: item.id,
          };
        })
        .reverse();
    } catch (e) {
      HttpClient.log(e, {
        component: 'GenericEntitiesStore',
        functionName: 'fetchUnits',
        codeLine: 656,
        requestUrl: GENERIC.units,
        method: 'GET',
      });

      Promise.reject(e);
    } finally {
      this.optionsLoading.units = false;
    }
  };

  cashierInitialize(): void {
    this.fetchVatsOptions();
    this.fetchUnits();
    this.fetchCountries();
  }

  initialize(): void {
    // TODO It is required to separate each request depend on a role.
    this.fetchVatsOptions();
  }

  clear(): void {
    // чтобы заново данные запрашивались при смене юзера (вышли и зашли под другим)
    this.optionsLoading = { ...optionsLoadingInitial };
    this.dictionary = { ...dictionaryInitial };
    this.options = { ...optionsInitial };
    this.vatsRaitsMap = null;
    this.defaultVatsRaitsMap = null;
  }
}
