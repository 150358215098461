import { FC, useCallback, useMemo } from 'react';
import CSSTransition from 'react-transition-group/CSSTransition';
import styles from './styles.module.scss';

interface Props {
  in: boolean;
  timeout: number;
}

const Fade: FC<Props> = (props) => {
  const { in: inProp, timeout, children } = props;

  const classNames = useMemo(() => {
    return {
      enter: styles.fadeEnter,
      enterActive: styles.fadeEnterActive,
      exit: styles.fadeExit,
      exitActive: styles.fadeExitActive,
    };
  }, []);

  const handleEnter = useCallback(
    (node: HTMLElement) => {
      node.style.transition = `opacity ${timeout}ms`;
    },
    [timeout],
  );

  return (
    <CSSTransition
      unmountOnExit
      in={inProp}
      timeout={timeout}
      classNames={classNames}
      onEnter={handleEnter}>
      {children}
    </CSSTransition>
  );
};

export default Fade;
