import { makeAutoObservable } from 'mobx';
import { hasMenuShown } from '@services/utils/storage';
import type { RootStore } from './index';
import { Confirm, Alert } from './entities/ui';
import { MENU_COLLAPSE_BREAKPOINT } from '../consts/app';

export default class UiStore {
  alert: Alert = {
    isOpen: false,
    title: '',
    text: '',
    buttonText: '',
  };

  confirm: Confirm = {
    isOpen: false,
    title: '',
    text: '',
    confirmButtonText: '',
    cancelButtonText: '',
    result: false,
    confirmButtonType: 'general',
    colorModal: true,
  };

  issuePointModalShown = false;

  menuShown = hasMenuShown() === null ? false : hasMenuShown();

  windowWidth = window.innerWidth;

  imageViewerOpen = false;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  setImageViewerOpen = (open: boolean): void => {
    this.imageViewerOpen = open;
  };

  setWindowWidth = (width: number): void => {
    this.windowWidth = width;
  };

  showAlert = (options: Partial<Omit<Alert, 'isOpen'>>): void => {
    const { text = '', title = '', buttonText = 'Закрыть' } = options;
    this.alert = {
      ...this.confirm,
      isOpen: true,
      text,
      title,
      buttonText,
    };
  };

  closeAlert() {
    this.alert.isOpen = false;
  }

  showConfirm = (options: Partial<Omit<Confirm, 'isOpen' | 'result'>>): void => {
    const {
      text = '',
      title = '',
      confirmButtonText = 'Да',
      cancelButtonText = 'Отмена',
      confirmButtonType = 'general',
      colorModal = true,
    } = options;
    this.confirm = {
      ...this.confirm,
      isOpen: true,
      text,
      title,
      confirmButtonText,
      cancelButtonText,
      confirmButtonType,
      colorModal,
    };
  };

  closeConfirm(result = false): void {
    this.confirm.result = result;
    this.confirm.isOpen = false;
  }

  toggleIssuePointModal(): void {
    this.issuePointModalShown = !this.issuePointModalShown;
  }

  setMenuVisible(flag: boolean): void {
    this.menuShown = flag;
  }

  toggleMenuShown = (): void => {
    this.menuShown = !this.menuShown;
  };

  get showStaticMenu(): boolean {
    return this.windowWidth >= MENU_COLLAPSE_BREAKPOINT;
  }
}
